import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import SellerApi from '../../../api/SellerApi'
import { AuthState, useAuthState } from '../../../auth/auth'
import { useCurrentTenantQuery } from '../../tenant/hooks/useReadTenant'
import { GetCompanyDefaultSettingsResponse } from '@valuecase/seller-backend'

type UseCompanyVisualSettingsProps = {
  onUpdateSuccess?: () => void
}

type CompanyVisualSettingsMutationData = {
  primaryColor?: string
  theme?: 'Light' | 'Dark' | null
  backgroundImage?: {
    s3Key: string
    source: string
  } | null
  logoImage?: {
    s3Key: string
    source: string
  } | null
  favicon?: {
    s3Key: string
    source: string
  } | null
  intercomId?: string
  fontFamily?: string | null
  fontFamilySpec?: string | null
  aiWritingAssistantIsEnabled?: boolean
  aiWritingAssistantOptOut?: boolean
  websiteUrl?: string
  tenantName?: string
}

const queryKey = ['companyVisualSettings']

async function queryFn(tenantId: string | undefined) {
  if (!tenantId) {
    return null
  }
  const res = await SellerApi.get<GetCompanyDefaultSettingsResponse, undefined>(
    `/tenants/${tenantId}/default-space-settings`,
  )
  return res.payload
}

async function mutationFn(
  auth: AuthState,
  tenantId: string,
  data: CompanyVisualSettingsMutationData,
) {
  if (!auth.authorized) {
    throw new Error('Authorization missing')
  }
  return await SellerApi.patch(`/tenants/${tenantId}/default-space-settings`, data)
}

export function useCompanyVisualSettings(props?: UseCompanyVisualSettingsProps) {
  const auth = useAuthState()
  const queryClient = useQueryClient()
  const { tenant, isFetched } = useCurrentTenantQuery()

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => queryFn(tenant?.payload?.id),
    retry: 1,
    staleTime: 1000 * 60, // 60 seconds
    enabled: isFetched && !!tenant?.payload?.id,
  })

  const mutation = useMutation({
    mutationFn: (data: CompanyVisualSettingsMutationData) => {
      if (!auth.authorized || !tenant?.payload?.id) {
        throw new Error('Authorization missing')
      }
      return mutationFn(auth, tenant.payload.id, data)
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })

      if (props?.onUpdateSuccess) {
        props.onUpdateSuccess()
      }
    },
  })

  return {
    // query
    isQueryLoading: query.isLoading,
    isQueryReloading: query.isRefetching && query.isStale,
    isQueryError: query.isError,
    refetchQuery: query.refetch,
    queryError: query.error,
    companySettings: query.data,
    isQueryFetched: query.isFetched,

    // mutation
    isMutationSuccess: mutation.isSuccess,
    isMutationLoading: mutation.isLoading,
    isMutationError: mutation.isError,
    mutationError: mutation.error,
    updateCompanySettings: (data: CompanyVisualSettingsMutationData) => mutation.mutate(data),
    updateCompanySettingsAsync: (data: CompanyVisualSettingsMutationData) =>
      mutation.mutateAsync(data),
  }
}
