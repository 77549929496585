import { useCreateSpace } from '@/modules/spaces/hooks/useCreateSpace'
import { useTemplateGeneration } from '@/modules/templates/TemplateCreator/TemplateGeneration/context/TemplateGenerationContext'
import { useOnboardTenant } from '@/modules/tenant/hooks/useOnboardTenant'
import { TTemplateGenerationRequestStatus } from '@valuecase/common'
import { Button2, Progress } from '@valuecase/ui-components'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TSelfSignUp } from '../types/TSelfSignUp'
import { SpaceInput } from '@/modules/spaces/hooks/types'
import { useTemplateGenerationProgress } from '@/modules/templates/hooks/useTemplateGenerationProgress'

const statusMessages = {
  [TTemplateGenerationRequestStatus.PREVIEW]: 'Initializing space generation',
  [TTemplateGenerationRequestStatus.STARTED]: 'Analyzing your requirements',
  [TTemplateGenerationRequestStatus.TEMPLATE_GENERATOR_RESPONSE_RECEIVED]:
    'Creating space structure',
  [TTemplateGenerationRequestStatus.BLOCK_CREATOR_RESPONSE_RECEIVED]: 'Validating space structure',
  [TTemplateGenerationRequestStatus.OUTPUT_VALIDATION]: 'Creating space',
  [TTemplateGenerationRequestStatus.COMPLETED]: 'Space set up complete - one moment please',
  [TTemplateGenerationRequestStatus.ERROR]: 'An error occurred during generation',
} as const

const useLoadingDots = () => {
  const [dots, setDots] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev === 3 ? 1 : prev + 1))
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return '.'.repeat(dots)
}

const CreateTemplateAndSpaceLoadingStep = ({ form }: { form: TSelfSignUp }) => {
  const { generationData } = useTemplateGeneration()
  const currentProgress = useTemplateGenerationProgress(generationData?.status)
  const { createSpaceAsync } = useCreateSpace()
  const spaceCreationCalled = useRef(false)
  const errorCalled = useRef(false)
  const { onboardTenantAsync } = useOnboardTenant()
  const loadingDots = useLoadingDots()

  const statusMessage = useMemo(() => {
    const message =
      statusMessages[generationData?.status as keyof typeof statusMessages] ||
      'Initializing space generation'
    return `${message}`
  }, [generationData?.status])

  // In case of error, we allow the tenant to onboard to be able to access the dashboard.
  const handleError = useCallback(async () => {
    await onboardTenantAsync({
      isOnboarded: true,
      error: true,
    })
  }, [onboardTenantAsync])

  const createAndNavigateToSpace = useCallback(async () => {
    if (generationData?.createdTemplateRootNodeId) {
      const payload: SpaceInput = {
        parentTemplateId: generationData.createdTemplateRootNodeId,
        companyName: form.companyName,
        contactFirstName: form.contactFirstName,
        contactLastName: form.contactLastName,
      }

      if (form.spaceLogo) {
        payload.logo = form.spaceLogo
      }

      try {
        const space = await createSpaceAsync(payload)
        await onboardTenantAsync({
          isOnboarded: true,
        })
        window.location.href = `/spaces/${space.id}?token=${space.unversionedData.auth.token}`
      } catch (error) {
        handleError()
      }
    }
  }, [
    generationData?.createdTemplateRootNodeId,
    form.companyName,
    form.contactFirstName,
    form.contactLastName,
    form.spaceLogo,
    createSpaceAsync,
    onboardTenantAsync,
    handleError,
  ])

  useEffect(() => {
    if (
      generationData?.status === TTemplateGenerationRequestStatus.COMPLETED ||
      generationData?.status === TTemplateGenerationRequestStatus.FALLBACK_USED
    ) {
      if (!spaceCreationCalled.current) {
        createAndNavigateToSpace()
        spaceCreationCalled.current = true
      }
    }
    if (generationData?.status === TTemplateGenerationRequestStatus.ERROR) {
      if (!errorCalled.current) {
        handleError()
        errorCalled.current = true
      }
    }
  }, [generationData?.status, createAndNavigateToSpace, handleError])

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-background/80 backdrop-blur-sm'>
      <div className='flex flex-col w-96 px-3 py-2 items-center justify-center'>
        {generationData?.status === TTemplateGenerationRequestStatus.ERROR ? (
          <>
            {' '}
            <span className='text-sm font-semibold text-grey-s6'>Ooops! Something went wrong.</span>
            <div className='flex items-center gap-1 mt-2'>
              <span className='text-xs text-grey-s5'>An error occurred during space creation.</span>
            </div>
            <Button2 className='mt-6' onClick={() => window.location.reload()}>
              Go to Dashboard
            </Button2>
          </>
        ) : (
          <>
            {' '}
            <span className='text-sm font-semibold text-grey-s6'>
              Creating Space, give our AI ~2 minutes
            </span>
            <Progress className='w-full bg-transparent' variant='info' value={currentProgress} />
            <div className='flex items-center gap-1'>
              <span className='text-xs text-grey-s5'>{statusMessage}</span>
              <span className='text-xs text-grey-s5 min-w-[24px] text-left'>{loadingDots}</span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CreateTemplateAndSpaceLoadingStep
