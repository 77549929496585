import { FC, useMemo } from 'react'
import { cn } from '../../lib'
import { cva, VariantProps } from 'class-variance-authority'
import { getInitialsLogoUrl } from '../../utils'
import { IonIcon } from '../../Icons/IonIcon'
import { IWhitelistedUserDealSide } from '@valuecase/common'

const thumbnailVariants = cva(
  cn(
    'inline-flex flex-col items-center justify-center',
    'relative bg-grey-s1',
    'overflow-hidden border border-solid border-grey-s2',
  ),
  {
    variants: {
      shape: {
        SQUARE: 'rounded',
        CIRCLE: 'rounded-full',
      },
      size: {
        EXTRASMALL: 'min-w-5 max-w-5 min-h-5 max-h-5 text-[8px]',
        SMALL: 'min-w-6 max-w-6 min-h-6 max-h-6 text-xxs',
        MEDIUM: 'min-w-8 max-w-8 min-h-8 max-h-8 text-xs',
        LARGE: 'min-w-10 max-w-10 min-h-10 max-h-10',
      },
    },
    defaultVariants: {
      size: 'MEDIUM',
      shape: 'SQUARE',
    },
  },
)

type ThumbnailVariants = VariantProps<typeof thumbnailVariants> & {
  className?: string
}

type InitialsProps = ThumbnailVariants & {
  // todo: replace once with type 'user' and gravatar background colors
  type: 'gravatarInitials'
  initials: string
}

type ImageProps = ThumbnailVariants & {
  type: 'image'
  imageUrl: string
}

type IconProps = ThumbnailVariants & {
  type: 'icon'
  icon: string
}

export type UserAvatarProps = ThumbnailVariants & {
  type: 'user'
  initials: string
  variant: 'LIGHT' | 'BOLD' | 'GREY'
}

/**
 * AvatarThumbnail component
 * See Figma design:
 * // todo: add link
 * https://www.figma.com/design/P8xXsOpyXX7tpK0bs2G9sQ/%F0%9F%A7%B1-VLCS-Design-System-2.0?node-id=52-4683&m=dev
 */
export const AvatarThumbnail: FC<InitialsProps | ImageProps | IconProps | UserAvatarProps> = ({
  className,
  ...props
}): JSX.Element => {
  return (
    <div
      className={cn(
        thumbnailVariants(props),
        {
          'rounded-lg': props.shape === 'SQUARE' && props.size === 'LARGE',
          'bg-primary-s5 text-white': props.type === 'user' && props.variant === 'BOLD',
          'bg-primary-s2 text-primary-s5 border-primary-s4':
            props.type === 'user' && props.variant === 'LIGHT',
          'bg-grey-s1 text-grey-s4 border-grey-s4':
            props.type === 'user' && props.variant === 'GREY',
        },
        className,
      )}
    >
      {props.type === 'image' && (
        // Alt text is not set because thumbnail is decorative and should be next to item it represents.
        <img className={cn('object-cover w-8 h-8')} alt='' src={props.imageUrl} />
      )}
      {props.type === 'gravatarInitials' && (
        <img
          className={'scale-125 w-8 h-8'}
          // Alt text is not set because thumbnail is decorative and should be next to item it represents.
          alt=''
          src={getInitialsLogoUrl(props.initials)}
        />
      )}
      {props.type === 'icon' && (
        <div className={'flex items-center justify-center w-full h-full'}>
          <IonIcon
            name={props.icon}
            className={cn('text-grey-s5', {
              'w-6 h-6': props.size === 'LARGE',
              'w-4 h-4  ': props.size === 'MEDIUM',
              'w-2 h-2': props.size === 'SMALL',
            })}
          />
        </div>
      )}
      {props.type === 'user' && (
        <div className={'flex items-center justify-center w-full h-full'}>
          <span
            className={cn('uppercase font-semibold', {
              'text-xxs': props.size === 'SMALL',
            })}
          >
            {props.initials}
          </span>
        </div>
      )}
    </div>
  )
}

export const SpaceUserAvatarThumbnail: FC<
  Omit<UserAvatarProps, 'variant'> & {
    dealSide: IWhitelistedUserDealSide
    greyedOut?: boolean
  }
> = ({ className, ...props }): JSX.Element => {
  const variant = useMemo(() => {
    if (props.greyedOut) {
      return 'GREY'
    }
    return props.dealSide === 'SELLER' ? 'BOLD' : 'LIGHT'
  }, [props.dealSide, props.greyedOut])

  return <AvatarThumbnail {...props} variant={variant} className={className} />
}
