import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useCallback,
  useState,
  ReactNode,
  HTMLAttributes,
} from 'react'
import { cn } from '../../../lib/utils'
import { Label } from '../label'
import * as React from 'react'
import RadioChecked from './radio-checked.svg?react'
import RadioUnchecked from './radio-unchecked.svg?react'

const RadioGroup = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Root>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return <RadioGroupPrimitive.Root className={cn('grid gap-2', className)} {...props} ref={ref} />
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupButtonCheckedExtension = ({
  children,
  background,
  ...props
}: HTMLAttributes<HTMLDivElement> & { background?: 'default' | 'primary' }) => {
  return (
    <div
      {...props}
      className={cn('p-4 pb-[calc(0.75rem-3px)] text-xs text-grey-s6', {
        'bg-primary-s2': background === 'primary',
      })}
    >
      {children}
    </div>
  )
}

interface RadioGroupButtonItemProps
  extends ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> {
  checkedExtension?: ReactNode
}

const RadioGroupButtonItem = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupButtonItemProps
>(({ className, children, checkedExtension, ...props }, ref) => {
  const [checked, setChecked] = useState(false)

  const radioItemRef = useCallback<
    (node: (HTMLButtonElement & { dataset: { state: 'checked' | 'unchecked' } }) | null) => void
  >((node) => {
    setChecked(node?.dataset.state === 'checked')
  }, [])

  return (
    <RadioGroupPrimitive.Item
      asChild
      role={'radio'}
      ref={radioItemRef}
      className={cn(
        'cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 overflow-hidden',
        className,
      )}
      {...props}
    >
      <div
        className={cn(
          'transition-colors rounded-lg border border-grey-s2 bg-white data-[state=checked]:border-primary-s5',
          'ring-offset-white focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-s3 focus-visible:ring-offset-2',
        )}
      >
        <div
          className={cn(
            'rounded-lg border-white border-solid border-[3px] hover:border-primary-s2',
            {
              'border-primary-s2 ': checked,
            },
          )}
        >
          <div className={'flex gap-3 items-center p-3.5'}>
            <div className={cn('w-4 flex items-center justify-center')}>
              {checked ? <RadioChecked /> : <RadioUnchecked />}
            </div>
            <Label className={'text-grey-s6  text-sm font-medium leading-none cursor-pointer'}>
              {children}
            </Label>
          </div>
          {checked && checkedExtension}
        </div>
      </div>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupButtonItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroup, RadioGroupButtonItem, RadioGroupButtonCheckedExtension }
