import { isInternalLink, UrlHelper } from '@valuecase/common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmbedTypes } from '../../components/legacy'
import {
  Input2,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
  Textarea,
} from '../../components/ui'
import { Button2 } from '../../components/ui/button'
import { IonIcon } from '../../Icons/IonIcon'
import CalendlyIcon from './assets/calendly.svg?react'
import DriveIcon from './assets/drive.svg?react'
import HubspotIcon from './assets/hubspot.svg?react'
import LoomIcon from './assets/loom.svg?react'
import MeetIcon from './assets/meet.svg?react'
import VidyardIcon from './assets/vidyard.svg?react'
import VimeoIcon from './assets/vimeo.svg?react'
import YoutubeIcon from './assets/youtube.svg?react'
import ZoomIcon from './assets/zoom.svg?react'

const empbedUrlPlaceholders: { [key in Exclude<EmbedTypes, 'html'> | 'link']: string } = {
  youtube: 'www.youtube.com/...',
  loom: 'www.loom.com/...',
  hubspot: 'www.hubspot.com/...',
  calendly: 'www.calendly.com/...',
  zoom: 'www.zoom.us/...',
  vidyard: 'www.vidyard.com/...',
  meet: 'https://drive.google.com/...',
  drive: 'https://drive.google.com/...',
  vimeo: 'www.vimeo.com/...',
  link: 'https://...',
}

export type TEmbedVariant = 'embedAndLink' | 'link'
export type TEmbedResult =
  | { type: 'EMBED'; embedType: 'html'; payload: string }
  | {
      type: 'EMBEDDABLE_LINK'
      embedType: Exclude<EmbedTypes, 'html'>
      url: string
      previewName: string
    }
  | { type: 'LINK'; url: string; previewName: string }

export const EmbedSelect = ({
  variant = 'embedAndLink',
  onInsert,
}: {
  variant: TEmbedVariant
  onInsert: (result: TEmbedResult) => void
}) => {
  const [selectedEmbedType, setSelectedEmbedType] = useState<EmbedTypes | 'link'>(
    variant === 'link' ? 'link' : 'youtube',
  )
  const { t } = useTranslation()
  const [urlOrEmbedPayload, setUrlOrEmbedPayload] = useState<string>('')

  const validUrl = useMemo(() => {
    if (selectedEmbedType === 'html') {
      return true
    }

    const trimmedURL = urlOrEmbedPayload.trim()
    if (!trimmedURL) {
      return true
    }

    if (isInternalLink(trimmedURL)) {
      return true
    }

    const validURL = UrlHelper.isURL(trimmedURL)

    if (!validURL) {
      return false
    }

    switch (selectedEmbedType) {
      case 'youtube':
        if (!validateYoutubeLink(trimmedURL)) {
          return false
        }
        break
      case 'zoom':
        if (!validateZoomLink(trimmedURL)) {
          return false
        }
        break
      case 'meet':
        if (!validateGoogleMeetLink(trimmedURL)) {
          return false
        }
        break
      default:
        return true
    }

    return true
  }, [selectedEmbedType, urlOrEmbedPayload])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()

        if (selectedEmbedType === 'link') {
          onInsert({
            type: 'LINK',
            url: urlOrEmbedPayload.trim(),
            previewName: humanReadableDomain(urlOrEmbedPayload),
          })
        } else if (selectedEmbedType === 'html') {
          onInsert({
            type: 'EMBED',
            embedType: selectedEmbedType,
            payload: urlOrEmbedPayload,
          })
        } else if (urlOrEmbedPayload) {
          onInsert({
            type: 'EMBEDDABLE_LINK',
            embedType: selectedEmbedType,
            url: urlOrEmbedPayload.trim(),
            previewName: humanReadableDomain(urlOrEmbedPayload),
          })
        }
      }}
    >
      <div className='w-full flex flex-col gap-2'>
        <p className={'text-grey-s4 text-xs font-semibold'}>
          {variant === 'embedAndLink' &&
            t('components.Embeddor.embedDescription', { defaultValue: 'Add an embed or a link' })}
          {variant === 'link' &&
            t('components.AddFileModalBody.website.title', {
              defaultValue: 'Enter website link or link of file',
            })}
        </p>

        {variant === 'embedAndLink' && (
          <div>
            <Select
              defaultValue={'youtube'}
              value={selectedEmbedType}
              onValueChange={(val) => setSelectedEmbedType(val as EmbedTypes)}
            >
              <SelectTrigger className='w-full'>
                <SelectValue placeholder='Select an embed type' />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup className={'text-sm'}>
                  <SelectLabel>Choose format</SelectLabel>
                  <SelectItem value='calendly'>
                    <div className='flex gap-2 items-center'>
                      <CalendlyIcon className='w-5 h-5' />
                      Calendly Meeting
                    </div>
                  </SelectItem>
                  <SelectItem value='meet'>
                    <div className='flex gap-2 items-center'>
                      <MeetIcon className='w-5 h-5' />
                      Google Meet
                    </div>
                  </SelectItem>
                  <SelectItem value='drive'>
                    <div className='flex gap-2 items-center'>
                      <DriveIcon className='w-5 h-5' />
                      Google Drive
                    </div>
                  </SelectItem>
                  <SelectItem value='hubspot'>
                    <div className='flex gap-2 items-center'>
                      <HubspotIcon className='w-5 h-5' />
                      Hubspot embed (e.g., Meeting, Form)
                    </div>
                  </SelectItem>
                  <SelectItem value='loom'>
                    <div className='flex gap-2 items-center'>
                      <LoomIcon className='w-5 h-5' />
                      Loom
                    </div>
                  </SelectItem>
                  <SelectItem value='vidyard'>
                    <div className='flex gap-2 items-center'>
                      <VidyardIcon className='w-5 h-5' />
                      Vidyard
                    </div>
                  </SelectItem>
                  <SelectItem value='vimeo'>
                    <div className='flex gap-2 items-center'>
                      <VimeoIcon className='w-5 h-5' />
                      Vimeo
                    </div>
                  </SelectItem>
                  <SelectItem value='youtube'>
                    <div className='flex gap-2 items-center'>
                      <YoutubeIcon className='w-5 h-5' />
                      Youtube
                    </div>
                  </SelectItem>
                  <SelectItem value='zoom'>
                    <div className='flex gap-2 items-center'>
                      <ZoomIcon className='w-5 h-5' />
                      Zoom
                    </div>
                  </SelectItem>

                  <SelectItem value='html'>
                    <div className='flex gap-2 items-center'>
                      <IonIcon name={'code-slash'} className='w-5 h-3 text-grey-s4' />
                      Custom Embed
                    </div>
                  </SelectItem>

                  {variant === 'embedAndLink' && (
                    <SelectItem value='link'>
                      <div className='flex gap-2 items-center'>
                        <IonIcon name={'link'} className='w-5 h-3 text-grey-s4' />
                        Link
                      </div>
                    </SelectItem>
                  )}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        )}

        <div className={'w-full flex gap-2 justify-between items-start'}>
          {selectedEmbedType === 'html' ? (
            <Textarea
              className={'resize-none'}
              data-test={'input-embed-html'}
              placeholder={`<${t('components.Embeddor.embedDescription', { defaultValue: 'Add an embed or a link' })}>`}
              rows={4}
              value={urlOrEmbedPayload ?? undefined}
              onInput={(e) => {
                setUrlOrEmbedPayload(e.currentTarget.value)
              }}
            />
          ) : (
            <Input2
              leadingIcon={'link'}
              data-test={'input-embed-link'}
              placeholder={empbedUrlPlaceholders[selectedEmbedType]}
              value={urlOrEmbedPayload}
              variant={validUrl ? 'default' : 'error'}
              onInput={(e) => setUrlOrEmbedPayload(e.currentTarget.value)}
            />
          )}
          <Button2
            type='submit'
            data-test={'embed-button'}
            disabled={!selectedEmbedType || !urlOrEmbedPayload || !validUrl}
            leadingIcon={'cloud-upload-outline'}
          >
            {t('components.Embeddor.embedButton', { defaultValue: 'Insert' })}
          </Button2>
        </div>
        {!validUrl && (
          <p className={'text-xs text-warning-s5'}>
            {t('components.Embeddor.invalidUrl', {
              defaultValue: 'Please enter a valid url.',
            })}
          </p>
        )}
        {selectedEmbedType === 'vidyard' && (
          <p className='text-grey-s4 text-xs font-bold mt-2'>
            {t('components.Embeddor.vidyardEmbeddingInfo.text', {
              defaultValue: 'Please make sure you have set the embed toggle in Vidyard to "ON".',
            })}{' '}
            <a
              href='https://knowledge.vidyard.com/hc/en-us/articles/360033244534'
              target='_blank'
              rel='noreferrer'
              className='font-bold text-primary-s5 hover:underline'
            >
              {t('components.Embeddor.vidyardEmbeddingInfo.learnMoreLink', {
                defaultValue: 'Learn more.',
              })}
            </a>
          </p>
        )}
        {selectedEmbedType === 'drive' && (
          <p className='text-grey-s4 text-xs font-bold mt-2'>
            {t('components.Embeddor.driveEmbeddingInfo', {
              defaultValue:
                'Please make sure you have selected the sharing option "anyone with the link"',
            })}
          </p>
        )}
      </div>
    </form>
  )
}

const humanReadableDomain = (link: string) => {
  try {
    const url = new URL(link.includes('://') ? link : `https://${link}`)
    const splittedHost = url.host.split('.')
    const [firstLetterOfDomain, ...otherLettersOfDomain] = splittedHost[splittedHost.length - 2]
    const name = `${firstLetterOfDomain.toUpperCase()}${otherLettersOfDomain
      .join('')
      .toLowerCase()}${url.pathname.split('/').join('_')}`
    if (name.endsWith('_')) {
      return name.substring(0, name.length - 1)
    }
    return name
  } catch (e: unknown) {
    return link
  }
}

const validateYoutubeLink = (url: string) => {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)

  return !!(match && match[2].length == 11)
}

const validateGoogleMeetLink = (url: string) => {
  const match = url.match(/(?:\/file\/d\/|id=)([\w-]+)(?:\/|\?|$)/)

  return !!(match && match[1])
}

const validateZoomLink = (url: string) => {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*(zoom\.us\/rec\/share\/)([^#\&\?]*).*/
  const match = url.match(regExp)

  return !!(match && match[2])
}
