import { CSSProperties } from 'react'
import { Skeleton } from '@valuecase/ui-components'
import DarkContainer from './assets/dark_container.svg?react'
import LightContainer from './assets/light_container.svg?react'
import SpaceHeader from './assets/space_header.svg?react'
import Testimonials from './assets/testimonials.svg?react'
import ArrowLeft from './assets/arrow_left.svg?react'
import ArrowRight from './assets/arrow_right.svg?react'
import ContentBlock from './assets/content_block.svg?react'
import TestimonialsCropped from './assets/testimonials_cropped.svg?react'
import TopbarNavItem from './assets/topbar_nav_item.svg?react'
import defaultBg from './assets/default_bg.png'

type SpacePreviewRenderProps = {
  backgroundImageUrl?: string
  companyLogoUrl?: string
  primaryColor: string
  theme?: 'Dark' | 'Light'
  averageLuminosity?: number
  isLoading?: boolean
}

export const SpacePreviewRender = ({
  backgroundImageUrl,
  companyLogoUrl,
  primaryColor,
  theme = 'Dark',
  averageLuminosity,
  isLoading = false,
}: SpacePreviewRenderProps) => {
  if (isLoading) {
    return (
      <div className='relative -mt-2'>
        <div
          className='absolute w-full top-[43.9746px] left-[9.30664px]'
          style={{ maxWidth: '325.63474px' }}
        >
          <Skeleton className='w-full h-[200px]' />
        </div>

        <div className='absolute top-[54px] left-[30px]'>
          <Skeleton className='w-48 h-8' />
        </div>

        {theme === 'Dark' ? <DarkContainer /> : <LightContainer />}
      </div>
    )
  }

  return (
    <div className='relative -mt-2'>
      <div
        className='absolute w-full top-[43.9746px] left-[9.30664px]'
        style={{ maxWidth: '325.63474px' }}
      >
        <img
          src={
            !backgroundImageUrl || backgroundImageUrl.trim().length === 0
              ? defaultBg
              : backgroundImageUrl
          }
          style={backgroundCss}
          alt={'space bg image'}
        />
      </div>

      <>
        {companyLogoUrl && companyLogoUrl.trim().length !== 0 && (
          <div className='absolute py-2 flex items-center top-2 left-[9.30664px] h-[35.5px] ml-4 max-w-[48px]'>
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={companyLogoUrl}
              alt={'company logo'}
            />
          </div>
        )}
      </>
      <div className='absolute top-[54px] left-[30px]'>
        <SpaceHeader fill={'rgba(255, 255, 255, 0.85)'} />
      </div>

      <div className='absolute top-[118.85px] left-[30px]'>
        <Testimonials />
      </div>
      <div className='absolute top-[175.85px] left-[21px]'>
        <ArrowLeft fill={primaryColor || '#FF6600'} />
      </div>
      <div className='absolute top-[175.85px] left-[300px]'>
        <ArrowRight fill={primaryColor || '#FF6600'} />
      </div>
      <div className='absolute top-[258.6px] left-[30px]'>
        <ContentBlock fill={primaryColor || '#FF6600'} />
      </div>

      <div className='absolute top-[369.49px] left-[30px]'>
        <TestimonialsCropped />
      </div>
      {theme === 'Dark' ? <DarkContainer /> : <LightContainer />}
      <div className='absolute top-[8px] left-[130px]'>
        <TopbarNavItem fill={primaryColor || '#FF6600'} />
      </div>
    </div>
  )
}

const backgroundCss: CSSProperties = {
  pointerEvents: 'none',
  width: '100%',
  minHeight: '100%',
  userSelect: 'none',
  objectFit: 'cover',
}
