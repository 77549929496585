import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import {
  TBaseAnalyticsQuery,
  TSpaceSummaryQuery,
  TTasksCompletedOverTimeGroupBy,
  TTasksCompletedOverTimeQuery,
  TTemplateSummaryQuery,
  actionPlanAnalyticsResponseSchema,
  TActionPlanAnalyticsResponse,
  tasksCompletedOverTimeResponseSchema,
  TTasksCompletedOverTimeResponse,
  spaceSummaryResponseSchema,
  TSpaceSummaryResponse,
} from '@valuecase/common'

export function setActionPlanAnalyticsBaseFilterParams(
  filter: Omit<
    TBaseAnalyticsQueryFilter,
    'includeArchivedTemplates' | 'templateIds' | 'actionPlanIds' | 'milestoneIds' | 'ownerIds'
  > &
    Pick<
      Partial<TBaseAnalyticsQueryFilter>,
      'includeArchivedTemplates' | 'templateIds' | 'actionPlanIds' | 'milestoneIds' | 'ownerIds'
    >,
  params: URLSearchParams,
) {
  if (filter.spaceCreatedAfter) {
    params.set('spaceCreatedAfter', filter.spaceCreatedAfter.toISOString())
  }
  if (filter.spaceCreatedBefore) {
    params.set('spaceCreatedBefore', filter.spaceCreatedBefore.toISOString())
  }
  if (filter.templateIds && filter.templateIds.size > 0) {
    params.set('templateIds', Array.from(filter.templateIds).join(','))
  }
  if (filter.actionPlanIds && filter.actionPlanIds.size > 0) {
    params.set('actionPlanIds', Array.from(filter.actionPlanIds).join(','))
  }
  if (filter.milestoneIds && filter.milestoneIds.size > 0) {
    params.set('milestoneIds', Array.from(filter.milestoneIds).join(','))
  }
  if (filter.ownerIds && filter.ownerIds.size > 0) {
    params.set('ownerIds', Array.from(filter.ownerIds).join(','))
  }
  if (typeof filter.includeArchivedTemplates === 'boolean') {
    params.set('includeArchivedTemplates', filter.includeArchivedTemplates.toString())
  }
  if (typeof filter.includeArchivedSpaces === 'boolean') {
    params.set('includeArchivedSpaces', filter.includeArchivedSpaces.toString())
  }
}

/**
 * Queries for action plan analytics
 */
export function useActionPlanAnalyticsQuery({ filter }: { filter: TTemplateSummaryQueryFilter }) {
  return useQuery({
    staleTime: 0,
    queryKey: [
      'taskAnalytics',
      Array.from(filter.templateIds || []),
      Array.from(filter.actionPlanIds || []),
      Array.from(filter.milestoneIds || []),
      filter.search,
      filter.groupingLevel,
      Array.from(filter.ownerIds || []),
      filter.includeArchivedTemplates,
      filter.includeArchivedSpaces,
      filter.spaceCreatedAfter,
      filter.spaceCreatedBefore,
    ],
    queryFn: async (): Promise<TActionPlanAnalyticsResponse> => {
      const params = new URLSearchParams()
      setActionPlanAnalyticsBaseFilterParams(filter, params)
      params.set('groupingLevel', filter.groupingLevel)
      if (filter.search) {
        params.set('search', filter.search)
      }
      const res = await spacesApi.get(
        `/action-plan-analytics/template-summary-table?${params.toString()}`,
      )
      return actionPlanAnalyticsResponseSchema.parse(res.data)
    },
  })
}

export function useActionPlanTasksOverTimeQuery({
  filter,
  groupBy,
  enabled,
}: {
  enabled: boolean
  groupBy: TTasksCompletedOverTimeGroupBy
  filter: TTasksCompletedOverTimeQueryFilter
}) {
  return useQuery({
    enabled,
    queryKey: [
      'tasksCompletedOverTime',
      Array.from(filter.templateIds || []),
      Array.from(filter.actionPlanIds || []),
      Array.from(filter.milestoneIds || []),
      filter.search,
      filter.groupingLevel,
      Array.from(filter.ownerIds || []),
      filter.includeArchivedTemplates,
      filter.includeArchivedSpaces,
      filter.spaceCreatedAfter,
      filter.spaceCreatedBefore,
      groupBy,
    ],
    queryFn: async (): Promise<TTasksCompletedOverTimeResponse> => {
      const params = new URLSearchParams()
      setActionPlanAnalyticsBaseFilterParams(filter, params)
      params.set('groupBy', groupBy)
      if (filter.search) {
        params.set('search', filter.search)
      }
      if (filter.groupingLevel) {
        params.set('groupingLevel', filter.groupingLevel)
      }
      const res = await spacesApi.get(
        `/action-plan-analytics/count-of-tasks-completed-over-time?${params.toString()}`,
      )
      return tasksCompletedOverTimeResponseSchema.parse(res.data)
    },
  })
}
export function useActionPlanAnalyticsCellDetailsQuery({
  filters,
}: {
  filters: TSpaceSummaryQueryFilter
}) {
  const cellDetailsEnabled = useMemo(() => {
    return !!(filters?.originNodeId && filters?.groupType)
  }, [filters?.originNodeId, filters?.groupType])
  const cellDetailsQuery = useQuery({
    staleTime: 0,
    enabled: cellDetailsEnabled,
    queryKey: [
      'cellDetails',
      filters.includeArchivedSpaces,
      filters.spaceCreatedAfter,
      filters.spaceCreatedBefore,
      filters.groupType,
      filters.originNodeId,
      filters.groupingLevel,
    ],
    queryFn: async (): Promise<TSpaceSummaryResponse> => {
      // For type safety; but query won't actually be enabled if it is true that neither
      // cellDetailsConfig.originNodeId nor cellDetailsConfig.groupType is defined
      if (!(filters?.originNodeId && filters?.groupType)) {
        return []
      }
      const params = new URLSearchParams()
      setActionPlanAnalyticsBaseFilterParams(filters, params)
      params.set('originNodeId', filters.originNodeId)
      params.set('groupingLevel', filters.groupingLevel)
      params.set('groupType', filters.groupType)
      const res = await spacesApi.get(
        `/action-plan-analytics/template-summary-spaces-group?${params.toString()}`,
      )
      return spaceSummaryResponseSchema.parse(res.data)
    },
  })
  return cellDetailsQuery
}

export type CellDetailsConfig = {
  title: string
  description: string
  groupType: TSpaceSummaryQuery['groupType']
  originNodeId: string
}

export type TTemplateSummaryQueryFilter = Omit<
  TTemplateSummaryQuery,
  'templateIds' | 'actionPlanIds' | 'milestoneIds' | 'ownerIds' | 'groupingLevel'
> & {
  templateIds: Set<string> | undefined
  actionPlanIds: Set<string> | undefined
  milestoneIds: Set<string> | undefined
  ownerIds: Set<string> | undefined
  groupingLevel: Required<TTemplateSummaryQuery>['groupingLevel']
}

export type TTasksCompletedOverTimeQueryFilter = Omit<
  TTasksCompletedOverTimeQuery,
  'templateIds' | 'actionPlanIds' | 'milestoneIds' | 'ownerIds'
> & {
  templateIds: Set<string> | undefined
  actionPlanIds: Set<string> | undefined
  milestoneIds: Set<string> | undefined
  ownerIds: Set<string> | undefined
}

export type TSpaceSummaryQueryFilter = Omit<TSpaceSummaryQuery, 'ownerIds'> & {
  ownerIds: Set<string> | undefined
}

export type TBaseAnalyticsQueryFilter = Omit<
  TBaseAnalyticsQuery,
  'templateIds' | 'actionPlanIds' | 'milestoneIds' | 'ownerIds'
> & {
  templateIds: Set<string> | undefined
  actionPlanIds: Set<string> | undefined
  milestoneIds: Set<string> | undefined
  ownerIds: Set<string> | undefined
}
