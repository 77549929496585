import React, { useCallback, useMemo } from 'react'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { DateTime } from 'luxon'
import {
  TTasksCompletedOverTimeQueryFilter,
  useActionPlanTasksOverTimeQuery,
} from './action-plan-analytics.helpers'
import { Skeleton } from '@valuecase/ui-components'
import { TTasksCompletedOverTimeGroupBy } from '@valuecase/common'

type TaskCompletionChartProps = {
  groupBy: TTasksCompletedOverTimeGroupBy
  filter: TTasksCompletedOverTimeQueryFilter
}

const chartHeight = 144
const maybeLabelHeight = 10
const topYAxisPadding = 20

export const TaskCompletionChart: React.FC<TaskCompletionChartProps> = ({ filter, groupBy }) => {
  const actionPlanTasksOverTimeQuery = useActionPlanTasksOverTimeQuery({
    filter,
    groupBy,
    enabled: true,
  })

  const chartDataCore = useMemo(() => {
    const chartDataRaw = actionPlanTasksOverTimeQuery.data
    if (!chartDataRaw || chartDataRaw.length === 0) {
      return []
    }
    return chartDataRaw
  }, [actionPlanTasksOverTimeQuery.data])

  const minDate = useMemo(() => {
    if (chartDataCore.length === 0) {
      return DateTime.now()
    }
    return DateTime.fromJSDate(chartDataCore[0].date, { zone: 'utc' })
  }, [chartDataCore])

  const maxDate = useMemo(() => {
    if (chartDataCore.length === 0) {
      return DateTime.now()
    }
    return DateTime.fromJSDate(chartDataCore[chartDataCore.length - 1].date, { zone: 'utc' })
  }, [chartDataCore])

  const hasMoreThanOneYearOfData = useMemo(() => {
    const monthsSinceMinDate = maxDate.diff(minDate, 'months').months
    return monthsSinceMinDate >= 12
  }, [maxDate, minDate])

  const formatDate = useCallback(
    (value: string) => {
      const date = DateTime.fromISO(value, { zone: 'utc' })
      if (groupBy === 'month') {
        return hasMoreThanOneYearOfData ? date.toFormat('MMM yyyy') : date.toFormat('MMM')
      } else if (groupBy === 'week') {
        return hasMoreThanOneYearOfData ? date.toFormat('MMM d yyyy') : date.toFormat('MMM d')
      } else {
        throw new Error('Invalid groupBy')
      }
    },
    [groupBy, hasMoreThanOneYearOfData],
  )

  const chartData = useMemo(() => {
    if (chartDataCore.length === 0) {
      return []
    }
    const dateToCountMap = new Map<string, number>(
      chartDataCore.map((item) => [
        (
          DateTime.fromJSDate(item.date, {
            zone: 'utc',
          }) as DateTime<true>
        ).toISODate(),
        item.count,
      ]),
    )
    let currentDate = minDate
    const chartDataFilled = []
    while (currentDate <= maxDate) {
      const dateStr = currentDate.toISODate()
      if (dateStr) {
        chartDataFilled.push({
          date: dateStr,
          count: dateToCountMap.get(dateStr) || 0,
        })
      }
      if (groupBy === 'month' || groupBy === 'week') {
        currentDate = currentDate.plus({ [groupBy]: 1 })
      } else {
        throw new Error('Invalid groupBy')
      }
    }
    return chartDataFilled.map((item) => ({
      name: item.date,
      value: item.count,
    }))
  }, [chartDataCore, minDate, maxDate, groupBy])

  const maxDataPoint = useMemo(() => {
    return Math.max(...chartData.map((item) => item.value || 0))
  }, [chartData])

  const horizontalPoints = useMemo(() => {
    const effectiveChartHeight = chartHeight - (maybeLabelHeight + topYAxisPadding)
    if (maxDataPoint <= 5) {
      return [topYAxisPadding, effectiveChartHeight]
    }
    const step = Math.floor(effectiveChartHeight / 5)
    const result = Array.from({ length: 6 }, (_, i) => i * step).slice(1, -1)
    result.push(effectiveChartHeight)
    return result
  }, [maxDataPoint])

  const totalDataPoints = useMemo(() => chartData.length, [chartData])

  const shouldShowDotsAndLabels = useMemo(() => {
    return totalDataPoints <= 20
  }, [totalDataPoints])

  return (
    <div className='space-y-4'>
      {actionPlanTasksOverTimeQuery.isLoading && (
        <Skeleton className='h-[144px] rounded-lg min-w-[300px]' />
      )}
      {!actionPlanTasksOverTimeQuery.isLoading && (
        <ResponsiveContainer width='100%' height={chartHeight} minWidth={300}>
          <LineChart
            data={chartData}
            className='bg-background rounded-md'
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid
              vertical={false}
              stroke='var(--theme-grey-s2)'
              horizontalPoints={horizontalPoints}
            />
            <YAxis padding={{ top: topYAxisPadding }} hide={true} domain={[0, 'dataMax']} />
            <XAxis
              dataKey='name'
              stroke='var(--theme-grey-s6)'
              tickLine={false}
              interval={'equidistantPreserveStart'}
              axisLine={false}
              tick={{ fontSize: 10, fill: '#60646C' }}
              textAnchor={'middle'}
              tickFormatter={formatDate}
              padding={{ left: 20, right: 20 }}
            />
            <Line
              animationDuration={200}
              dataKey={'value'}
              type='monotone'
              stroke='var(--theme-blue-s4)'
              strokeWidth={3}
              dot={shouldShowDotsAndLabels ? { fill: 'var(--theme-blue-s4)', r: 2 } : false}
              activeDot={
                shouldShowDotsAndLabels
                  ? { fill: 'var(--theme-blue-s4)', stroke: 'var(--theme-blue-s4)', r: 4 }
                  : false
              }
              label={
                shouldShowDotsAndLabels
                  ? {
                      position: 'top',
                      fill: 'var(--theme-grey-s6)',
                      fontSize: 12,
                      fontWeight: '400',
                      dy: -3,
                    }
                  : false
              }
              connectNulls
            />
            <Tooltip
              cursor={shouldShowDotsAndLabels ? false : true}
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const date = DateTime.fromISO(payload[0].payload.name, { zone: 'utc' })
                  return (
                    <div className='bg-white p-1 border rounded shadow flex flex-col gap-[2px]'>
                      <div className='flex items-center gap-1'>
                        <span className='text-xxs leading-[14px] font-semibold text-grey-s6'>
                          {groupBy === 'week'
                            ? `${date.toFormat('MMM d')} - ${date.plus({ weeks: 1 }).minus({ days: 1 }).toFormat('MMM d')}`
                            : date.toFormat('MMMM')}
                        </span>
                        <span className='text-xxs leading-[14px] text-grey-s5'>
                          {payload[0].value}
                        </span>
                      </div>
                      <span className='text-xxs leading-[14px] text-grey-s5'>Tasks</span>
                    </div>
                  )
                }
                return null
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}
