import {
  EmptyState,
  EmptyStateAction,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateTitle,
} from '@valuecase/ui-components/src/components/ui/empty'
import DudeWithAPlusSign from '../../assets/dude-with-a-plus-sign.svg?react'
import { useCallback } from 'react'

export const NoTasksExistEmptyState = () => {
  const handleLearnMoreClick = useCallback(() => {
    window.open('https://help.valuecase.de/en/articles/77752-action-plan-block')
  }, [])

  return (
    <div className='flex w-full h-full justify-center align-middle'>
      <EmptyState className='p-4 gap-4'>
        <DudeWithAPlusSign className='w-32 h-32' />
        <EmptyStateHeader className='max-w-[312px]'>
          <EmptyStateTitle>Start a New Action Plan!</EmptyStateTitle>
          <EmptyStateDescription>
            Ready to boost efficiency? Set up an Action Plan for your active spaces to organize
            tasks, track progress, and keep everyone aligned!
          </EmptyStateDescription>
        </EmptyStateHeader>
        <EmptyStateAction
          className='py-0 h-auto'
          variant={'link'}
          onClick={handleLearnMoreClick}
          trailingIcon='arrow-forward'
        >
          Learn more
        </EmptyStateAction>
      </EmptyState>
    </div>
  )
}
