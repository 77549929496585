import { TActionPlanTaskAnalyticsRecord } from '@valuecase/common'
import React, { useMemo, useCallback } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  TooltipProps,
  YAxis,
} from 'recharts'

type AverageCompletionDaysChartProps = {
  data: Partial<Pick<TActionPlanTaskAnalyticsRecord, 'title' | 'avgCompletionDays'>>[]
}

const chartHeight = 144
const maybeLabelHeight = 10
const topYAxisPadding = 20

export const AverageCompletionDaysChart: React.FC<AverageCompletionDaysChartProps> = ({ data }) => {
  const filteredData = useMemo(() => {
    return data
      .filter((item) => typeof item.avgCompletionDays === 'number')
      .map((item) => ({ name: item.title, value: item.avgCompletionDays }))
  }, [data])

  const averageCompletionDays = useMemo(() => {
    if (filteredData.length === 0) {
      return 0
    }
    const sum = filteredData.reduce((acc, item) => acc + (item.value || 0), 0)
    return Math.ceil(sum / filteredData.length)
  }, [filteredData])

  const DataTooltip = useCallback(({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <div className='bg-white p-1 border rounded shadow flex flex-col gap-[2px]'>
          <div className='flex items-center gap-1 text-xxs leading-[14px]'>
            <span className='font-semibold text-grey-s6'>{label}</span>{' '}
            <span className='text-grey-s5'>{Math.ceil(payload[0].value as number)}</span>
          </div>
          <span className='text-xxs leading-[14px] text-grey-s5'>Template</span>
        </div>
      )
    }
    return null
  }, [])

  const formatXAxisLabel = useCallback(
    (value: string) => {
      if (filteredData.length > 15) {
        return value.slice(0, 1)
      } else if (filteredData.length > 8) {
        return value.slice(0, 3) + '...'
      } else if (filteredData.length > 5) {
        return value.slice(0, 8) + '...'
      }
      return value
    },
    [filteredData.length],
  )

  const maxDataPoint = useMemo(() => {
    return Math.max(...filteredData.map((item) => item.value || 0))
  }, [filteredData])

  const horizontalPoints = useMemo(() => {
    const effectiveChartHeight = chartHeight - (maybeLabelHeight + topYAxisPadding)
    if (maxDataPoint === 0) {
      return [Math.round((effectiveChartHeight + topYAxisPadding) / 2)]
    }
    if (maxDataPoint < 5) {
      return [topYAxisPadding, effectiveChartHeight]
    }
    const step = Math.floor(effectiveChartHeight / 5)
    const result = Array.from({ length: 6 }, (_, i) => i * step).slice(1, -1)
    result.push(effectiveChartHeight)
    return result
  }, [maxDataPoint])

  return (
    <ResponsiveContainer width='100%' height={chartHeight} minWidth={300}>
      <BarChart
        data={filteredData}
        className='bg-background rounded-md'
        maxBarSize={15}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis
          dataKey={'name'}
          tickLine={false}
          axisLine={false}
          tick={{ fontSize: 10, fill: '#60646C' }}
          stroke='var(--theme-grey-s6)'
          interval={'preserveStart'}
          tickFormatter={formatXAxisLabel}
          padding={{ left: 20, right: 20 }}
        />
        <YAxis padding={{ top: topYAxisPadding }} hide={true} domain={[0, 'dataMax']} />
        <Tooltip content={<DataTooltip />} cursor={{ fill: 'var(--theme-primary-s2)' }} />
        <CartesianGrid
          stroke='var(--theme-grey-s2)'
          vertical={false}
          horizontalPoints={horizontalPoints}
        />
        <Bar
          dataKey={'value'}
          fill='var(--theme-blue-s4)'
          radius={[4, 4, 0, 0]}
          animationDuration={200}
        >
          <LabelList
            position='top'
            className='fill-foreground text-xs leading-[14px]'
            formatter={(value: number) => Math.ceil(value)}
            fill='var(--theme-grey-s6)'
          />
        </Bar>
        <ReferenceLine
          y={averageCompletionDays}
          stroke='var(--theme-blue-s5)'
          strokeDasharray='8 4'
          strokeWidth={2}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
