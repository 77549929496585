import * as React from 'react'
import * as ProgressPrimitive from '@radix-ui/react-progress'

import { cva, VariantProps } from 'class-variance-authority'
import { cn } from '../../lib/utils'

const progressIndicatorVariants = cva('h-full w-full flex-1', {
  variants: {
    variant: {
      neutral: 'bg-grey-s3',
      attention: 'bg-[#FDBA74]',
      success: 'bg-success-s4',
      info: 'bg-primary-s4',
      danger: 'bg-warning-s4',
      ai: 'bg-gradient-to-r from-ai-s5-from via-ai-s5-via to-ai-s5-to',
    },
  },
  defaultVariants: {
    variant: 'neutral',
  },
})

const progressBackgroundVariants = cva('relative h-1.5 w-full overflow-hidden rounded-full', {
  variants: {
    variant: {
      neutral: 'bg-grey-s2',
      attention: 'bg-grey-s2',
      success: 'bg-grey-s2',
      info: 'bg-grey-s2',
      danger: 'bg-grey-s2',
      ai: 'bg-white',
    },
  },
  defaultVariants: {
    variant: 'neutral',
  },
})

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> &
    VariantProps<typeof progressIndicatorVariants>
>(({ className, value, variant, ...props }, ref) => (
  <div className={cn('flex items-center gap-2', className)}>
    <ProgressPrimitive.Root
      ref={ref}
      className={cn(progressBackgroundVariants({ variant }))}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={cn(progressIndicatorVariants({ variant }))}
        style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
      />
    </ProgressPrimitive.Root>
    <span className={'w-9 text-xs text-grey-s5 text-left'}>{Math.round(value || 0)}%</span>
  </div>
))
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
