type GradientImageDimensions = {
  width: number
  height: number
}

const GRADIENT_DIMENSIONS: GradientImageDimensions = {
  width: 1920,
  height: 1080,
} as const

export const generateGradientImage = (primaryColor: string): Promise<File> => {
  return new Promise((resolve, reject) => {
    try {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      if (!ctx) {
        throw new Error('Could not get canvas context')
      }

      canvas.width = GRADIENT_DIMENSIONS.width
      canvas.height = GRADIENT_DIMENSIONS.height

      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height)
      gradient.addColorStop(0, `${primaryColor}01`)
      gradient.addColorStop(1, '#F6F6F6')

      // Fill canvas with gradient
      ctx.fillStyle = gradient
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Convert canvas to blob with high quality
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const file = new File([blob], 'background-gradient.png', {
              type: 'image/png',
            })
            resolve(file)
          } else {
            reject(new Error('Failed to create blob from canvas'))
          }
        },
        'image/png',
        1, // Maximum quality
      )
    } catch (error) {
      reject(error)
    }
  })
}
