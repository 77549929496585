// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useRef, useState } from 'react'
import { useAuthState } from '../../../auth/auth'
import { useCurrentTenantQuery } from '../../tenant/hooks/useReadTenant'

window.intercomSettings = {
  hide_default_launcher: false,
}

type IntercomStateType = 'idle' | 'booted' | 'updated'
// const intercomState: IntercomStateType = 'idle'

const IntercomComponent = ({ hideDefaultLauncher = true }: { hideDefaultLauncher?: boolean }) => {
  const [intercomInstalled, setIntercomInstalled] = useState(false)
  const [intercomState, setIntercomState] = useState<IntercomStateType>('idle')

  const authState = useAuthState()
  const { tenant, isReloading, isLoading } = useCurrentTenantQuery()

  useEffect(() => {
    if (!intercomInstalled && import.meta.env.VITE_INTERCOM_ENABLED === 'true') {
      console.debug('Installing Intercom, options:', window.intercomSettings)
      /* eslint-disable */
      ;(function () {
        var w: any = window
        var ic = w.Intercom
        if (typeof ic === 'function') {
          ic('reattach_activator')
          ic('update', w.intercomSettings)
        } else {
          var d = document
          var i: any = function () {
            i.c(arguments)
          }
          i.q = []
          i.c = function (args: any) {
            i.q.push(args)
          }
          w.Intercom = i
          var l = function () {
            var s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://widget.intercom.io/widget/' + import.meta.env.VITE_INTERCOM_APP_ID
            var x: any = d.getElementsByTagName('script')[0]
            x.parentNode.insertBefore(s, x)
          }
          if (document.readyState === 'complete') {
            l()
          } else if (w.attachEvent) {
            w.attachEvent('onload', l)
          } else {
            w.addEventListener('load', l, false)
          }
        }
      })()
      /* eslint-enable */

      setIntercomInstalled(true)
    }
  }, [intercomInstalled])

  const intercomUpdateTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    console.debug('Updating intercom settings')

    if (!authState.authorized || import.meta.env.VITE_INTERCOM_ENABLED !== 'true') {
      return
    }

    if (
      authState.loading ||
      authState.reloading ||
      isLoading ||
      isReloading ||
      !authState.authorized
    ) {
      console.debug('Cancelling intercom update ... auth is still loading ⏱')
    } else {
      console.debug('Auth state = finished loading ✅')
    }

    if (!window.intercomSettings) {
      console.debug("window.intercomSettings didn't exist yet, instantiated empty object")
      window.intercomSettings = {}
    }

    window.intercomSettings.api_base =
      import.meta.env.VITE_INTERCOM_API_BASE ?? 'https://api-iam.intercom.io'
    window.intercomSettings.app_id = import.meta.env.VITE_INTERCOM_APP_ID
    window.intercomSettings.hide_default_launcher = hideDefaultLauncher
    window.intercomSettings.user_id = authState.sub
    window.intercomSettings.email = authState.email
    window.intercomSettings.name = authState.name
    window.intercomSettings.user_role = authState.role?.name || 'unknown'

    if (tenant?.payload.id && tenant?.payload.tenantName) {
      window.intercomSettings.company = {
        company_id: tenant.payload.id,
        name: tenant.payload.tenantName,
      }
    }
    console.debug('New intercom settings:', window.intercomSettings)

    if (intercomUpdateTimeoutRef.current) {
      console.debug('Cancelling old intercom boot/update timeout')
      clearTimeout(intercomUpdateTimeoutRef.current)
      intercomUpdateTimeoutRef.current = null
    }

    console.debug('Current intercom state:', intercomState)

    if (intercomState === 'idle') {
      console.debug('Scheduling intercom >> boot <<')

      intercomUpdateTimeoutRef.current = setTimeout(() => {
        console.debug('Executing scheduled intercom boot')

        /* eslint-disable */
        Intercom('boot', window.intercomSettings)
        /* eslint-enable */

        console.debug('... finished scheduled intercom boot')

        setIntercomState('booted')
      }, 1000)
    } else {
      console.debug('Scheduling intercom >> update <<')

      intercomUpdateTimeoutRef.current = setTimeout(() => {
        console.debug('Executing scheduled intercom boot')

        /* eslint-disable */
        Intercom('update', window.intercomSettings)
        /* eslint-enable */

        console.debug('... finished scheduled intercom boot')

        setIntercomState('updated')
      }, 1000)
    }
  }, [intercomState, isLoading, isReloading, tenant, authState, hideDefaultLauncher])

  return null
}

export default IntercomComponent
