import { TGenerateTemplateDto } from '@valuecase/common'
import { Button2 } from '@valuecase/ui-components'
import { useCallback, useMemo, useState } from 'react'
import { TemplateGenerationPromptStage } from './stages/TemplateGenerationPromptStage'
import { TemplateGenerationReviewStage } from './stages/TemplateGenerationReviewStage'
import { useTemplateGeneration } from './context/TemplateGenerationContext'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { FlowStep, FullScreenFlow } from './FullScreenFlow'

export const TemplateGenerationFlow = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [form, setForm] = useState<TGenerateTemplateDto>({
    context: '',
    useCase: 'onboarding',
  })
  const { stageTemplateRequest, startTemplateGeneration, isStaging, currentRequest } =
    useTemplateGeneration()
  const { trackEvent } = useTrackEvent()

  const handleNext = useCallback(() => {
    if (currentStep === 0) {
      stageTemplateRequest({ data: { ...form }, skipPreview: false })
      setCurrentStep(1)
    }

    if (currentStep === 1) {
      if (currentRequest) {
        startTemplateGeneration(currentRequest.id)
      }
    }
  }, [currentStep, stageTemplateRequest, form, currentRequest, startTemplateGeneration])

  const handleBack = useCallback(() => {
    setCurrentStep(0)
  }, [])

  const handleUpdateForm = useCallback((field: keyof TGenerateTemplateDto, value: string) => {
    setForm((prev) => ({ ...prev, [field]: value }))
  }, [])

  const handlePromptSelect = useCallback(
    (prompt: string) => {
      trackEvent({
        event: 'templates-newtemplate-ai-select_prompt',
        eventProperties: {
          'Template Prompt': prompt,
        },
      })
    },
    [trackEvent],
  )

  const handleUseCaseSelect = useCallback(
    (useCase: string) => {
      trackEvent({
        event: 'templates-newtemplate-ai-usecase',
        eventProperties: {
          aiTemplateUseCase: useCase,
        },
      })
    },
    [trackEvent],
  )

  const handlePromptChange = useCallback(
    (prompt: string) => {
      trackEvent({
        event: 'templates-newtemplate-ai-edit_prompt',
        eventProperties: {
          context: prompt,
        },
      })
    },
    [trackEvent],
  )

  const nextIsDisabled = useMemo(() => {
    return currentStep === 0 && (form.context?.length === 0 || form.useCase === null)
  }, [currentStep, form.context?.length, form.useCase])

  const steps = useMemo<FlowStep[]>(
    () => [
      {
        id: 'prompt',
        component: TemplateGenerationPromptStage,
        title: 'Create Template with AI',
        titleSubtext: 'Tell us about what you want to build.',
        icon: 'c_ai-sparkles',
        rightButton: (
          <Button2
            disabled={nextIsDisabled}
            trailingIcon='arrow-forward-outline'
            onClick={handleNext}
          >
            Next
          </Button2>
        ),
        componentProps: {
          onPromptSelect: handlePromptSelect,
          onUseCaseSelect: handleUseCaseSelect,
          onPromptChange: handlePromptChange,
        },
      },
      {
        id: 'review',
        component: TemplateGenerationReviewStage,
        title: 'Content Review',
        titleSubtext: 'Review the pages content before starting to create your template.',
        icon: 'c_ai-sparkles',
        leftButton: (
          <Button2 variant='ghost' leadingIcon='arrow-back-outline' onClick={handleBack}>
            Revisit Instructions
          </Button2>
        ),
        rightButton: (
          <Button2 disabled={isStaging} leadingIcon='checkmark-outline' onClick={handleNext}>
            Create Template
          </Button2>
        ),
      },
    ],
    [
      nextIsDisabled,
      handleNext,
      handleBack,
      isStaging,
      handlePromptSelect,
      handleUseCaseSelect,
      handlePromptChange,
    ],
  )

  return (
    <FullScreenFlow
      isOpen={isOpen}
      onClose={onClose}
      currentStep={currentStep}
      steps={steps}
      componentProps={{
        form,
        onUpdateForm: handleUpdateForm,
      }}
      className='bg-gradient-to-br from-ai-s1-from via-ai-s1-via to-ai-s1-to'
    />
  )
}
