import { useEffect } from 'react'
import { useCompanyVisualSettings } from '../hooks/useCompanyVisualSettings'
import { useSignedImageUrl } from '../../../api/spacesApi/signedS3Images'
import { useImageColorAnalysis } from '@valuecase/ui-components'
import { SpacePreviewRender } from './SpacePreviewRender'

const SpacePreview = () => {
  const { companySettings: visualSettings } = useCompanyVisualSettings()
  const { imageUrl: companyLogoUrl } = useSignedImageUrl(visualSettings?.logoImage)
  const { imageUrl: bgImgUrl } = useSignedImageUrl(visualSettings?.backgroundImage)
  const { triggerImageColorAnalysis, imageColorAnalysis } = useImageColorAnalysis()

  useEffect(() => {
    if (bgImgUrl) {
      triggerImageColorAnalysis(bgImgUrl)
    }
  }, [bgImgUrl, triggerImageColorAnalysis])

  if (!visualSettings) return null

  return (
    <SpacePreviewRender
      backgroundImageUrl={bgImgUrl}
      companyLogoUrl={companyLogoUrl}
      primaryColor={visualSettings.primaryColor || '#FF6600'}
      theme={visualSettings.theme}
      averageLuminosity={imageColorAnalysis?.averageLuminosity}
    />
  )
}

export default SpacePreview
