import SellerApi from '@/api/SellerApi'
import { useMutation } from '@tanstack/react-query'
import { CompanyInfo, CompanyInfoSchema } from '@valuecase/common'

const fetchCompanyMetadata = async (website: string): Promise<CompanyInfo | null> => {
  const response = await SellerApi.get(`/current-tenant/onboard/${encodeURIComponent(website)}`)
  const parsed = CompanyInfoSchema.safeParse(response.payload)

  if (!parsed.success) {
    console.error('Failed to parse company metadata', parsed.error)
    return null
  }

  return parsed.data as CompanyInfo
}

export const useGetCompanyMetadata = () => {
  const mutation = useMutation({
    mutationFn: fetchCompanyMetadata,
  })

  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    data: mutation.data,
    getCompanyMetadata: mutation.mutate,
    getCompanyMetadataAsync: mutation.mutateAsync,
  }
}
