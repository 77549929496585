import {
  cn,
  IonIcon,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@valuecase/ui-components'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { promptsByUseCase } from '../constants/promptData'

type Props = {
  onUpdateForm: (field: string, value: string) => void
  onPromptSelect?: (prompt: string) => void
  onUseCaseSelect?: (useCase: string) => void
  onPromptChange?: (prompt: string) => void
  form: {
    useCase: string
    context: string
  }
}

export const TemplateGenerationPromptStage = ({
  onUpdateForm,
  onPromptSelect,
  onUseCaseSelect,
  onPromptChange,
  form,
}: Props) => {
  const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null)
  const debounceTimerRef = useRef<NodeJS.Timeout>()

  const currentPrompts = useMemo(() => promptsByUseCase[form.useCase] || [], [form.useCase])

  const handlePromptSelect = useCallback(
    (promptName: string) => {
      const selectedPromptData = currentPrompts.find((p) => p.promptName === promptName)
      if (selectedPromptData) {
        setSelectedPrompt(promptName)
        onUpdateForm('context', selectedPromptData.prompt)
        onPromptSelect?.(selectedPromptData.prompt)
      }
    },
    [onUpdateForm, currentPrompts, onPromptSelect],
  )

  const handleContextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newContext = e.target.value
      setSelectedPrompt(null)
      onUpdateForm('context', newContext)

      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }

      debounceTimerRef.current = setTimeout(() => {
        onPromptChange?.(newContext)
      }, 3000)
    },
    [onUpdateForm, onPromptChange],
  )

  const handleUseCaseChange = useCallback(
    (useCase: string) => {
      onUpdateForm('useCase', useCase)
      onUseCaseSelect?.(useCase)
    },
    [onUpdateForm, onUseCaseSelect],
  )

  // Keep selected prompt in sync with form context
  useEffect(() => {
    const matchingPrompt = currentPrompts.find((p) => p.prompt.trim() === form.context)
    if (!matchingPrompt) {
      setSelectedPrompt(null)
    }
  }, [form.context, currentPrompts])

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }
    }
  }, [])

  return (
    <div className='flex flex-col gap-6 w-full'>
      <div className='flex flex-col items-center justify-center mt-4'>
        <span className='text-xs font-semibold text-grey-s6'>
          What is the purpose of this template?
        </span>

        <div className='flex justify-center gap-6 w-full mt-3'>
          {/* Use Case Cards */}
          <UseCaseCard
            title='Onboarding'
            icon='c_team-work'
            isSelected={form.useCase === 'onboarding'}
            onSelect={() => handleUseCaseChange('onboarding')}
          />
          <UseCaseCard
            title='Sales'
            icon='c_virtual-meeting'
            isSelected={form.useCase === 'sales'}
            onSelect={() => handleUseCaseChange('sales')}
          />
          <UseCaseCard
            title='Other'
            icon='c_surfing-chart'
            isSelected={form.useCase === 'other'}
            onSelect={() => handleUseCaseChange('other')}
          />
        </div>

        {/* Prompt Input */}
        <div className='flex flex-col w-full rounded-lg bg-white/50 p-8 mt-8 items-center justify-center gap-3'>
          <span className='text-xs font-semibold text-grey-s6'>Specify use case</span>
          <Textarea
            className='text-xs text-grey-s5 bg-white border-grey-s2'
            rows={5}
            placeholder='Write instructions...'
            value={form.context}
            onChange={handleContextChange}
          />

          <span className='text-xs text-grey-s5'>Or start with an example</span>

          <div className='flex flex-row tall:flex-col w-full gap-3'>
            {currentPrompts.map((card) => (
              <PromptCard
                key={card.promptName}
                {...card}
                isSelected={selectedPrompt === card.promptName}
                onSelect={() => handlePromptSelect(card.promptName)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const PromptCard = ({
  promptName,
  description,
  prompt,
  isSelected,
  onSelect,
}: {
  promptName: string
  description: string
  prompt: string
  isSelected: boolean
  onSelect: () => void
}) => {
  return (
    <div
      className={cn(
        'w-full lg:flex-1 rounded-md bg-white/85 h-14  hover:bg-white/50 transition-colors cursor-pointer',
        {
          'border-2 border-ai-s4-from bg-white/50': isSelected,
        },
      )}
      onClick={onSelect}
    >
      <Tooltip delayDuration={200}>
        <TooltipTrigger asChild>
          <div className='flex flex-col p-3 justify-start items-start w-full'>
            <span className='text-xs font-semibold text-grey-s6 text-left'>{promptName}</span>
            <span className='text-xs text-grey-s5 text-left'>{description}</span>
          </div>
        </TooltipTrigger>

        <TooltipContent side='right' className='p-0 shadow-md'>
          <div className='flex flex-col gap-2 p-6 bg-white rounded-md'>
            <span className='text-xs font-semibold text-grey-s6'>{promptName}</span>
            <span className='max-w-md text-xs text-grey-s5'>{prompt}</span>
          </div>
        </TooltipContent>
      </Tooltip>
    </div>
  )
}

const UseCaseCard = ({
  title,
  icon,
  isSelected,
  onSelect,
}: {
  title: string
  icon: string
  isSelected: boolean
  onSelect: () => void
}) => (
  <div
    className={cn('flex flex-col cursor-pointer rounded-lg w-36 h-40', {
      'border-2 border-ai-s4-from': isSelected,
    })}
    onClick={onSelect}
  >
    <div className='flex items-center justify-center bg-white/50 rounded-t-lg'>
      <IonIcon name={icon} className='w-28 h-28' />
    </div>
    <div className=' bg-white rounded-b-lg flex items-center justify-center gap-2 h-full'>
      <span className='text-xs font-semibold text-grey-s5'>{title}</span>
      {isSelected && <IonIcon name='checkmark-circle' className='text-blue-s5' />}
    </div>
  </div>
)
