import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { useMutation } from '@tanstack/react-query'
import { TTemplateGenerationRequest } from '@valuecase/common'
import { useCallback } from 'react'

export const useGenerateTemplate = () => {
  const mutation = useMutation((templateGenerationRequestId: string) =>
    spacesApi.post<TTemplateGenerationRequest>(
      `/templates/generate/ai/${templateGenerationRequestId}/confirm`,
    ),
  )

  const generateTemplate = useCallback(
    async (templateGenerationRequestId: string) => {
      const response = await mutation.mutateAsync(templateGenerationRequestId)
      return response.data
    },
    [mutation],
  )

  return {
    generateTemplate,
    isGenerating: mutation.isLoading,
    error: mutation.error,
    success: mutation.isSuccess,
  }
}
