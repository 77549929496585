import { User, WhitelistedUserDealSide } from '@valuecase/common'
import {
  SearchableUserItem,
  SearchableUserList,
  UserChip,
  UserChipList,
} from '@valuecase/ui-components'
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateTitle,
} from '@valuecase/ui-components/src/components/ui/empty'

type Props = {
  users: User[]
  toggleMember: (member: User) => void
  selectedMembers: Set<User>
}

const UserSelection = ({ users, selectedMembers, toggleMember }: Props) => {
  return (
    <>
      {users?.length === 0 && (
        <EmptyState icon={'american-football'} iconSize={'small'} className={'py-4'}>
          <EmptyStateHeader>
            <EmptyStateTitle>No users</EmptyStateTitle>
            <EmptyStateDescription>
              There are no users in your organization yet.
            </EmptyStateDescription>
          </EmptyStateHeader>
        </EmptyState>
      )}
      <UserChipList className={'max-h-32 overflow-y-auto'}>
        {[...selectedMembers].map((member) => (
          <UserChip
            key={member.id}
            name={member.fullName ?? ''}
            dealSide={WhitelistedUserDealSide.SELLER}
            onRemove={() => toggleMember(member)}
          />
        ))}
      </UserChipList>

      {users && users.length > 0 && (
        <SearchableUserList
          searchPlaceholder={'Search members...'}
          userCount={users.length}
          className={'h-64 overflow-y-auto'}
        >
          {users.map((user) => {
            return (
              <SearchableUserItem
                key={user.id}
                user={{
                  name: user.fullName ?? 'N/A',
                  email: user.email,
                  dealSide: WhitelistedUserDealSide.SELLER,
                  unsubscribed: false,
                }}
                selected={selectedMembers.has(user)}
                onSelect={() => toggleMember(user)}
              />
            )
          })}
        </SearchableUserList>
      )}
    </>
  )
}

export default UserSelection
