import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  Button2,
  FilterDropdown,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  extractTitleFromQuillObject,
  Input2,
  Switch,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  useToggleIdSelection,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  UTCRangeCalendar,
  DateRangePickerProps,
  Skeleton,
  cn,
  EmptyState,
  EmptyStateAction,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateTitle,
} from '@valuecase/ui-components'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useReadTemplates } from '../templates/hooks/useReadTemplates'
import { ActionPlanAnalyticsTableGroup } from './ActionPlanGroup'
import { useReadAvailableSpaceOwners } from '../spaces/hooks/useReadAvailableSpaceOwners'
import {
  CellDetailsConfig,
  TTemplateSummaryQueryFilter,
  useActionPlanAnalyticsQuery,
  useActionPlanTasksOverTimeQuery,
} from './action-plan-analytics.helpers'
import { AverageCompletionDaysChart } from './charts/AverageCompletionDaysChart'
import { TaskCompletionChart } from './TaskCompletionChart'
import { ActionPlanAnalyticsCellDialog } from './ActionPlanAnalyticsCellDialog'
import { ActionPlanAnalyticsTableHeader } from './ActionPlanAnalyticsTableHeader'
import { Label } from '@valuecase/ui-components/src/components/ui/label'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import DudeWithAPlusSign from '../../assets/dude-with-a-plus-sign.svg?react'
import {
  TActionPlanAnalyticsGroupingLevel,
  TActionPlanAnalyticsResponse,
  TTasksCompletedOverTimeGroupBy,
  TTemplateSummarySpacesGroupType,
} from '@valuecase/common'
import { useHasPermission } from '@/auth/permissions'

const analyticsTableGridClass =
  'grid grid-cols-[1fr_max-content_max-content_max-content_max-content_max-content_max-content]'
const lastWeek = 'Last week' as const
const lastTwoWeeks = 'Last 2 weeks' as const
const thisMonth = 'This month' as const
const lastMonth = 'Last month' as const
const lastThreeMonths = 'Last 3 months' as const
const thisQuarter = 'This quarter' as const
const lastQuarter = 'Last quarter' as const
const lastTwelveMonths = 'Last 12 months' as const

type TimeRangePeriod =
  | typeof lastWeek
  | typeof lastTwoWeeks
  | typeof thisMonth
  | typeof lastMonth
  | typeof lastThreeMonths
  | typeof thisQuarter
  | typeof lastQuarter
  | typeof lastTwelveMonths
  | 'custom'

type TimeRange = {
  greaterThanOrEqualTo: DateTime
  lessThan: DateTime | undefined
}

const ActionPlanAnalytics = () => {
  /**
   * Basic state variables for the analytics page
   */
  const [search, setSearch] = useState('')
  const hasSpacesAllAdministrationPermission = useHasPermission('SPACES_ALL_ADMINISTRATION')
  const [timeRangePeriod, setTimeRangePeriod] = useState<TimeRangePeriod | undefined>(
    lastThreeMonths,
  )
  const defaultStartDateTime = useMemo(
    () => DateTime.now().setZone('UTC', { keepLocalTime: true }).minus({ months: 3 }),
    [],
  )
  const defaultEndDateTime = useMemo(
    () => DateTime.now().setZone('UTC', { keepLocalTime: true }),
    [],
  )
  const [hasAnalyticsData, setHasAnalyticsData] = useState<boolean | undefined>(undefined)
  const [timeRange, setTimeRange] = useState<TimeRange | undefined>({
    greaterThanOrEqualTo: defaultStartDateTime,
    lessThan: defaultEndDateTime,
  })
  const [dateSelectionOpen, setDateSelectionOpen] = useState(false)
  const flagsQuery = useLDflags()
  const [selectedTimeRange, setSelectedTimeRange] = useState<
    Parameters<DateRangePickerProps['onChange']>[0]
  >({
    startDate: defaultStartDateTime.toJSDate(),
    endDate: defaultEndDateTime.toJSDate(),
  })
  const handleTimePeriodSelection = useCallback(
    (value: TimeRangePeriod | undefined) => {
      let greaterThanOrEqualTo: DateTime | undefined = undefined
      let lessThan: DateTime | undefined = undefined
      switch (value) {
        case lastWeek:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('day')
            .minus({ days: 6 })
            .startOf('day')
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('day')
          break
        case lastTwoWeeks:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('day')
            .minus({ days: 13 })
            .startOf('day')
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('day')
          break
        case thisMonth:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('month')
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('day')
          break
        case lastMonth:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('month')
            .minus({ months: 1 })
          lessThan = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('month')
            .minus({ days: 1 })
          break
        case lastThreeMonths:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .minus({ months: 3 })
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('day')
          break
        case thisQuarter:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('quarter')
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('day')
          break
        case lastQuarter:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('quarter')
            .minus({ quarters: 1 })
          lessThan = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('quarter')
            .minus({ day: 1 })
          break
        case lastTwelveMonths:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .minus({ months: 12 })
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('month')
          break
        default:
          break
      }
      if (!greaterThanOrEqualTo || !lessThan) {
        return
      }
      setTimeRangePeriod(value)
      setTimeRange({ greaterThanOrEqualTo, lessThan })
      setSelectedTimeRange({
        startDate: greaterThanOrEqualTo.toJSDate(),
        endDate: lessThan.toJSDate(),
      })
    },
    [setTimeRange],
  )
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [groupBy, setGroupBy] = useState<TTasksCompletedOverTimeGroupBy>('month')
  const [includeArchivedSpaces, setIncludeArchivedSpaces] = useState(true)
  const [includeArchivedTemplates, setIncludeArchivedTemplates] = useState(false)
  const [ownerIds, setOwnerIds] = useState<Set<string> | undefined>(undefined)
  const toggleOwnerSelection = useToggleIdSelection({
    selectedIds: ownerIds,
    setSelectedIds: setOwnerIds,
  })
  const [templateIds, setTemplateIds] = useState<Set<string> | undefined>(undefined)
  const toggleTemplateSelection = useToggleIdSelection({
    selectedIds: templateIds,
    setSelectedIds: setTemplateIds,
  })
  const [actionPlanIds, setActionPlanIds] = useState<Set<string> | undefined>(undefined)
  const toggleActionPlanSelection = useToggleIdSelection({
    selectedIds: actionPlanIds,
    setSelectedIds: setActionPlanIds,
  })
  const [milestoneIds, setMilestoneIds] = useState<Set<string> | undefined>(undefined)
  const toggleMilestoneSelection = useToggleIdSelection({
    selectedIds: milestoneIds,
    setSelectedIds: setMilestoneIds,
  })
  const [isCellDetailsDialogOpen, setIsCellDetailsDialogOpen] = useState(false)
  const [cellDetailsConfig, setCellDetailsConfig] = useState<CellDetailsConfig | undefined>(
    undefined,
  )
  const [groupingLevel, setGroupingLevel] = useState<TActionPlanAnalyticsGroupingLevel>('template')
  const spaceCreatedAfter = useMemo(() => timeRange?.greaterThanOrEqualTo.toJSDate(), [timeRange])
  const spaceCreatedBefore = useMemo(() => {
    if (!timeRangePeriod) {
      return undefined
    }
    // This dates only have end dates for calendar view purposes
    const skipEndDate = [
      lastWeek,
      lastTwoWeeks,
      thisMonth,
      lastThreeMonths,
      thisQuarter,
      lastTwelveMonths,
    ].includes(timeRangePeriod as any)
    if (skipEndDate) {
      return undefined
    }
    return timeRange?.lessThan?.plus({ days: 1 }).toJSDate()
  }, [timeRange, timeRangePeriod])

  const { trackEvent } = useTrackEvent()

  useEffect(() => {
    trackEvent({
      event: 'dashboard-visit',
      eventProperties: {
        indexPageName: 'Action Plan Analytics page',
      },
    })
  }, [trackEvent])

  /**
   * Variables set in connection with querying the backend
   */
  // Owner data from the backend
  const { availableSpaceOwners } = useReadAvailableSpaceOwners()
  const sortedOwners = useMemo(() => {
    if (!availableSpaceOwners || availableSpaceOwners.length === 0) {
      return []
    }
    return [...availableSpaceOwners]
      .map((owner) => ({
        id: owner.id,
        title: `${owner.firstName} ${owner.lastName}`,
        email: owner.email,
      }))
      .sort((a, b) => a.title.localeCompare(b.title))
  }, [availableSpaceOwners])

  // Template data from the backend
  const { templates: templatesResponse } = useReadTemplates()
  const templates = useMemo(() => templatesResponse || [], [templatesResponse])

  // Action plan data from the backend
  const actionPlanTemplatesQuery = useQuery({
    queryKey: ['actionPlanTemplates', includeArchivedTemplates],
    queryFn: async () => {
      const res = await spacesApi.get(
        `/action-plans/templates?includeArchived=${includeArchivedTemplates}`,
      )
      const data = res.data as { items: { id: string; title: string }[] }
      if (data && typeof data === 'object' && Array.isArray(data.items)) {
        data.items = data.items.map((item) => ({
          ...item,
          title: extractTitleFromQuillObject(item.title) || '',
        }))
      }
      return data
    },
    staleTime: 0,
  })
  const actionPlans = useMemo(() => {
    return actionPlanTemplatesQuery.data?.items || []
  }, [actionPlanTemplatesQuery])

  // Milestone data from the backend (if enabled)
  const milestonesFilterEnabled = useMemo(() => {
    return !!templateIds || !!actionPlanIds
  }, [templateIds, actionPlanIds])
  const milestoneTemplatesQuery = useQuery({
    queryKey: [
      'milestoneTemplates',
      templateIds && Array.from(templateIds).join(','),
      actionPlanIds && Array.from(actionPlanIds).join(','),
      includeArchivedTemplates,
    ],
    queryFn: async () => {
      if (!templateIds && !actionPlanIds) {
        return { items: [] }
      }
      const params = new URLSearchParams()
      if (templateIds) {
        params.set('templateIds', Array.from(templateIds).join(','))
      }
      if (actionPlanIds) {
        params.set('actionPlanNodeIds', Array.from(actionPlanIds).join(','))
      }
      if (includeArchivedTemplates) {
        params.set('includeArchived', includeArchivedTemplates.toString())
      }
      const res = await spacesApi.get(`/action-plan-milestones/templates?${params.toString()}`)
      const data = res.data as { items: { id: string; title: string }[] }
      if (data?.items) {
        data.items = data.items.map((item) => ({
          ...item,
          title: extractTitleFromQuillObject(item.title) || '',
        }))
      }
      return data
    },
    enabled: milestonesFilterEnabled,
    staleTime: 0,
  })
  const milestones = useMemo(() => {
    return milestoneTemplatesQuery.data?.items || []
  }, [milestoneTemplatesQuery.data])

  // Task analytics data from the backend
  const filter: TTemplateSummaryQueryFilter = useMemo(
    () => ({
      templateIds,
      actionPlanIds,
      milestoneIds,
      search,
      groupingLevel,
      ownerIds,
      includeArchivedTemplates,
      includeArchivedSpaces,
      spaceCreatedAfter,
      spaceCreatedBefore,
    }),
    [
      templateIds,
      actionPlanIds,
      milestoneIds,
      search,
      groupingLevel,
      ownerIds,
      includeArchivedTemplates,
      includeArchivedSpaces,
      spaceCreatedAfter,
      spaceCreatedBefore,
    ],
  )
  const taskAnalyticsQuery = useActionPlanAnalyticsQuery({ filter })
  const taskAnalytics = useMemo(
    () =>
      taskAnalyticsQuery.data ||
      ({ items: [], meta: { lastRefreshed: undefined } } as TActionPlanAnalyticsResponse),
    [taskAnalyticsQuery.data],
  )
  useEffect(() => {
    if (hasAnalyticsData) {
      return
    }
    if (!taskAnalyticsQuery.isSuccess) {
      return
    }
    setHasAnalyticsData(taskAnalytics.items.length > 0)
  }, [hasAnalyticsData, taskAnalytics.items.length, taskAnalyticsQuery.isSuccess])

  // Tasks over time data from the backend
  const actionPlanTasksOverTimeQuery = useActionPlanTasksOverTimeQuery({
    filter,
    groupBy,
    enabled: groupingLevel === 'template',
  })
  const totalTasksCompleted = useMemo(() => {
    if (!actionPlanTasksOverTimeQuery) {
      return 0
    }
    return actionPlanTasksOverTimeQuery?.data?.reduce((acc, curr) => acc + curr.count, 0) ?? 0
  }, [actionPlanTasksOverTimeQuery])

  // Request to trigger a refresh of the analytics data via a mutation
  const refreshMutation = useMutation({
    mutationFn: async () => {
      return spacesApi.post('/action-plan-analytics/refresh')
    },
  })

  const timeRangeButtonLabelText = useMemo(() => {
    switch (timeRangePeriod) {
      case lastWeek:
        return 'Spaces created last week'
      case lastTwoWeeks:
        return 'Spaces created in the last 2 weeks'
      case thisMonth:
        return 'Spaces created this month'
      case lastMonth:
        return 'Spaces created last month'
      case lastThreeMonths:
        return 'Spaces created in the last 3 months'
      case thisQuarter:
        return 'Spaces created this quarter'
      case lastQuarter:
        return 'Spaces created last quarter'
      case lastTwelveMonths:
        return 'Spaces created in the last 12 months'
      default:
        if (!selectedTimeRange.startDate && !selectedTimeRange.endDate) {
          return 'All time'
        }
        return `Spaces created between ${DateTime.fromJSDate(
          selectedTimeRange.startDate || new Date(),
        ).toLocaleString()} and ${DateTime.fromJSDate(
          selectedTimeRange.endDate || new Date(),
        ).toLocaleString()}`
    }
  }, [selectedTimeRange.endDate, selectedTimeRange.startDate, timeRangePeriod])

  const handleCellClick = useCallback(
    ({
      originNodeId,
      groupType,
    }: {
      originNodeId: string
      groupType: TTemplateSummarySpacesGroupType
    }) => {
      if (!originNodeId || !groupType) {
        return
      }
      switch (groupType) {
        case 'quickest':
          setCellDetailsConfig({
            title: 'Quickest Quartile',
            description: `The quartile of spaces that took the shortest time to complete this ${groupingLevel}`,
            originNodeId,
            groupType,
          })
          break
        case 'slowest':
          setCellDetailsConfig({
            title: 'Slowest Quartile',
            description: `The quartile of spaces that took the longest time to complete this ${groupingLevel}`,
            originNodeId,
            groupType,
          })
          break
        case 'days to completion':
          setCellDetailsConfig({
            title: 'Days to completion',
            description: `The number of days it took to complete this ${groupingLevel}`,
            originNodeId,
            groupType,
          })
          break
        case 'completed':
          setCellDetailsConfig({
            title: 'Completed',
            description: `Spaces which have completed this ${groupingLevel}`,
            originNodeId,
            groupType,
          })
          break
        case 'space count':
          setCellDetailsConfig({
            title: 'Spaces',
            description: `Spaces containing this ${groupingLevel}`,
            originNodeId,
            groupType,
          })
          break
      }
      setIsCellDetailsDialogOpen(true)
    },
    [groupingLevel, setCellDetailsConfig, setIsCellDetailsDialogOpen],
  )

  const spacesCompletedCount = useMemo(
    () => taskAnalytics?.items?.reduce((acc, curr) => acc + (curr?.completedCount || 0), 0),
    [taskAnalytics],
  )
  const spaceCount = useMemo(
    () => taskAnalytics?.items?.reduce((acc, curr) => acc + (curr?.spaceCount || 0), 0) ?? 0,
    [taskAnalytics],
  )
  const totalAvgCompletionDays = useMemo(() => {
    if (!taskAnalytics || !taskAnalytics.items) {
      return 0
    }
    const filteredData = taskAnalytics.items.filter(
      (item) => typeof item.avgCompletionDays === 'number',
    )
    const sum = filteredData.reduce((acc, curr) => acc + (curr.avgCompletionDays || 0), 0)
    return filteredData.length > 0 ? Math.ceil(sum / filteredData.length) : 0
  }, [taskAnalytics])

  const showEmptyState = useMemo(() => {
    return taskAnalyticsQuery.isSuccess && hasAnalyticsData === false
  }, [hasAnalyticsData, taskAnalyticsQuery.isSuccess])

  const showNoMatchesState = useMemo(() => {
    return (
      taskAnalyticsQuery.isSuccess && hasAnalyticsData === true && taskAnalytics.items.length === 0
    )
  }, [hasAnalyticsData, taskAnalytics.items.length, taskAnalyticsQuery.isSuccess])

  const cellDetailsFilter = useMemo(() => {
    if (!cellDetailsConfig) {
      return undefined
    }
    return {
      ...filter,
      originNodeId: cellDetailsConfig.originNodeId,
      groupType: cellDetailsConfig.groupType,
    }
  }, [cellDetailsConfig, filter])

  return (
    <>
      {showEmptyState && (
        <div className='pt-11 w-full h-full flex flex-col flex-grow'>
          <h1 className={'text-2xl font-bold mb-4'}>Analytics</h1>
          <div className='flex-grow flex w-full items-center justify-center'>
            <EmptyState className='p-4 gap-4'>
              <DudeWithAPlusSign className='w-32 h-32' />
              <EmptyStateHeader className='max-w-[312px]'>
                <EmptyStateTitle>Template Your First Action Plan!</EmptyStateTitle>
                <EmptyStateDescription>
                  And build understanding on how your action plan strategy is working
                </EmptyStateDescription>
              </EmptyStateHeader>
              <EmptyStateAction
                className='py-0 h-auto'
                variant={'link'}
                onClick={() =>
                  window.open('https://help.valuecase.de/en/articles/77752-action-plan-block')
                }
                trailingIcon='arrow-forward'
              >
                Learn more
              </EmptyStateAction>
            </EmptyState>
          </div>
        </div>
      )}
      {!showEmptyState && (
        <div className='py-11 w-full flex flex-col'>
          {/* // When clicking on, for example, the lower quartile cell for a task, this dialog
       lists the bottom 25% of spaces that took the longest to complete the task */}
          {!!cellDetailsFilter && (
            <ActionPlanAnalyticsCellDialog
              open={isCellDetailsDialogOpen}
              onOpenChange={setIsCellDetailsDialogOpen}
              cellDetailsConfig={cellDetailsConfig}
              filters={cellDetailsFilter}
            />
          )}
          <div className={'flex flex-col w-full flex-grow'}>
            <h1 className={'text-2xl font-bold'}>Analytics</h1>
            <Tabs
              value={groupingLevel}
              onValueChange={(value) => {
                if (search) {
                  setSearch('')
                }
                setGroupingLevel(value as typeof groupingLevel)
                trackEvent({
                  event: 'analytics-action_plan-tab',
                  eventProperties: {
                    actionPlanTab:
                      value === 'template'
                        ? 'Summary'
                        : value.charAt(0).toUpperCase() + value.slice(1),
                  },
                })
              }}
            >
              <div className='flex flex-row justify-between mt-6 mb-4 border-b border-grey-s2'>
                <TabsList className='h-7 gap-3 pl-0'>
                  <TabsTrigger
                    className='pt-0 pb-2 px-1 text-grey-s5 font-semibold'
                    value='template'
                  >
                    Summary
                  </TabsTrigger>
                  <TabsTrigger
                    className='pt-0 pb-2 px-1 text-grey-s5 font-semibold'
                    value='action plan'
                  >
                    Action Plan
                  </TabsTrigger>
                  <TabsTrigger
                    className='pt-0 pb-2 px-1 text-grey-s5 font-semibold'
                    value='milestone'
                  >
                    Milestones
                  </TabsTrigger>
                  <TabsTrigger className='pt-0 pb-2 px-1 text-grey-s5 font-semibold' value='task'>
                    Tasks
                  </TabsTrigger>
                </TabsList>
                <DropdownMenu open={dateSelectionOpen} onOpenChange={setDateSelectionOpen}>
                  <DropdownMenuTrigger asChild>
                    <Button2 variant={'ghost'} size={'small'} trailingIcon='chevron-down'>
                      {timeRangeButtonLabelText}
                    </Button2>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align='end' className='pr-0'>
                    <div>
                      <div className='flex flex-row justify-end items-center pt-2 pb-4 pr-4'>
                        <Label className='ml-auto mr-2 text-xs font-semibold text-grey-s4'>
                          Start date
                        </Label>
                        <Input2
                          className='w-auto mr-8'
                          inputClassName='appearance-none hide-calendar-icon'
                          id='spaces-created-after-date'
                          type='date'
                          value={DateTime.fromJSDate(
                            selectedTimeRange.startDate || new Date(),
                          ).toFormat('yyyy-MM-dd')}
                          onChange={(e) => {
                            if (!e.target.value) {
                              return
                            }
                            setTimeRangePeriod('custom')
                            setSelectedTimeRange({
                              ...selectedTimeRange,
                              startDate: DateTime.fromISO(e.target.value, {
                                zone: 'UTC',
                              }).toJSDate(),
                            })
                          }}
                        />
                        <Label className='mr-2 text-xs font-semibold text-grey-s4'>End date</Label>
                        <Input2
                          className='w-auto'
                          inputClassName='appearance-none hide-calendar-icon'
                          id='spaces-created-before-date'
                          type='date'
                          value={DateTime.fromJSDate(
                            selectedTimeRange.endDate || new Date(),
                          ).toFormat('yyyy-MM-dd')}
                          onChange={(e) => {
                            if (!e.target.value) {
                              return
                            }
                            setTimeRangePeriod('custom')
                            setSelectedTimeRange({
                              ...selectedTimeRange,
                              endDate: DateTime.fromISO(e.target.value, {
                                zone: 'UTC',
                              }).toJSDate(),
                            })
                          }}
                        />
                      </div>
                      <div className='flex flew-row'>
                        <div className='pr-4 pt-4'>
                          <DropdownMenuRadioGroup
                            value={timeRangePeriod}
                            onValueChange={(value) =>
                              handleTimePeriodSelection(value as TimeRangePeriod)
                            }
                          >
                            <DropdownMenuRadioItem value={lastWeek}>
                              Last week
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={lastTwoWeeks}>
                              Last 2 weeks
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={thisMonth}>
                              This month
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={lastMonth}>
                              Last month
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={lastThreeMonths}>
                              Last 3 months
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={thisQuarter}>
                              This quarter
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={lastQuarter}>
                              Last quarter
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={lastTwelveMonths}>
                              Last 12 months
                            </DropdownMenuRadioItem>
                          </DropdownMenuRadioGroup>
                        </div>
                        <div>
                          <div className='p-4 bg-background'>
                            <div className='bg-white rounded-lg p-4 overflow-hidden'>
                              <UTCRangeCalendar
                                startDate={selectedTimeRange.startDate}
                                endDate={selectedTimeRange.endDate}
                                defaultMonth={
                                  timeRange?.greaterThanOrEqualTo.toJSDate() ||
                                  DateTime.now().minus({ months: 1 }).toJSDate()
                                }
                                numberOfMonths={2}
                                onChange={(value) => {
                                  setSelectedTimeRange(value)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-row justify-end p-4 pb-2'>
                        <Button2
                          size={'small'}
                          leadingIcon='checkmark'
                          disabled={!selectedTimeRange.startDate || !selectedTimeRange.endDate}
                          onClick={() => {
                            if (!selectedTimeRange.startDate || !selectedTimeRange.endDate) {
                              return
                            }
                            setTimeRangePeriod('custom')
                            setTimeRange({
                              greaterThanOrEqualTo: DateTime.fromJSDate(
                                selectedTimeRange.startDate,
                              ),
                              lessThan: DateTime.fromJSDate(selectedTimeRange.endDate),
                            })
                            setDateSelectionOpen(false)
                          }}
                        >
                          Apply Changes
                        </Button2>
                      </div>
                    </div>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className='flex flex-col items-start gap-3 relative mt-6 w-full mb-7'>
                <div className='flex items-center gap-2 relative self-stretch w-full flex-[0_0_auto]'>
                  <div className='gap-2 flex items-center relative w-full'>
                    <Input2
                      leadingIcon='search'
                      className='max-w-[200px]'
                      placeholder='Search'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    {templateIds && (
                      <FilterDropdown
                        items={templates}
                        selectedIds={templateIds}
                        toggleSelection={toggleTemplateSelection}
                        setSelectedIds={setTemplateIds}
                        searchPlaceholder='Search templates'
                        labelForNoSelection='All templates'
                        labelForSelection='Templates'
                      />
                    )}
                    {actionPlanIds && (
                      <FilterDropdown
                        items={actionPlans}
                        selectedIds={actionPlanIds}
                        toggleSelection={toggleActionPlanSelection}
                        setSelectedIds={setActionPlanIds}
                        searchPlaceholder='Search action plans'
                        labelForNoSelection='All action plans'
                        labelForSelection='Action plans'
                      />
                    )}
                    {ownerIds && (
                      <FilterDropdown
                        items={sortedOwners}
                        selectedIds={ownerIds}
                        toggleSelection={toggleOwnerSelection}
                        setSelectedIds={setOwnerIds}
                        searchPlaceholder='Search owners'
                        labelForNoSelection='All owners'
                        labelForSelection='Owners'
                      />
                    )}
                    {milestoneIds && (
                      <FilterDropdown
                        items={milestones}
                        selectedIds={milestoneIds}
                        toggleSelection={toggleMilestoneSelection}
                        setSelectedIds={setMilestoneIds}
                        searchPlaceholder='Search milestones'
                        labelForNoSelection='All milestones'
                        labelForSelection='Milestones'
                      />
                    )}
                    <div className='ml-auto'>
                      {(templateIds || milestoneIds || actionPlanIds || ownerIds) && (
                        <Button2
                          variant={'outlined'}
                          leadingIcon='refresh'
                          className='mr-2 text-grey-s5 font-semibold'
                          onClick={() => {
                            setTemplateIds(undefined)
                            setMilestoneIds(undefined)
                            setActionPlanIds(undefined)
                            setOwnerIds(undefined)
                          }}
                        >
                          Reset
                        </Button2>
                      )}
                      <DropdownMenu open={filtersOpen} onOpenChange={setFiltersOpen}>
                        <DropdownMenuTrigger asChild>
                          <Button2 variant={'solid'} leadingIcon='options' className='ml-auto'>
                            Filters
                          </Button2>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          className='w-[276px] flex flex-col gap-4 pt-4 pb-4 px-3'
                          align='end'
                        >
                          <FilterDropdown
                            items={sortedOwners}
                            selectedIds={ownerIds}
                            toggleSelection={toggleOwnerSelection}
                            setSelectedIds={setOwnerIds}
                            searchPlaceholder='Search owners'
                            labelForNoSelection={
                              hasSpacesAllAdministrationPermission ? 'All owners' : 'My spaces'
                            }
                            labelForSelection='Owners'
                            disabled={!hasSpacesAllAdministrationPermission}
                          />
                          <FilterDropdown
                            items={templates}
                            selectedIds={templateIds}
                            toggleSelection={toggleTemplateSelection}
                            setSelectedIds={setTemplateIds}
                            searchPlaceholder='Search templates'
                            labelForNoSelection='All templates'
                            labelForSelection='Templates'
                          />
                          <FilterDropdown
                            items={actionPlans}
                            selectedIds={actionPlanIds}
                            toggleSelection={toggleActionPlanSelection}
                            setSelectedIds={setActionPlanIds}
                            searchPlaceholder='Search action plans'
                            labelForNoSelection='All action plans'
                            labelForSelection='Action plans'
                          />
                          {milestonesFilterEnabled && (
                            <FilterDropdown
                              items={milestones}
                              selectedIds={milestoneIds}
                              toggleSelection={toggleMilestoneSelection}
                              setSelectedIds={setMilestoneIds}
                              searchPlaceholder='Search milestones'
                              labelForNoSelection='All milestones'
                              labelForSelection='Milestones'
                            />
                          )}
                          <div className='w-full flex justify-between flex-row px-1 py-1 items-center'>
                            <span className='text-xs font-semibold'>Include Archived Spaces</span>
                            <Switch
                              checked={includeArchivedSpaces}
                              onChange={setIncludeArchivedSpaces}
                            />
                          </div>
                          <div className='w-full flex justify-between flex-row px-1 py-1 items-center'>
                            <span className='text-xs font-semibold'>Show Archived Templates</span>
                            <Switch
                              checked={includeArchivedTemplates}
                              onChange={setIncludeArchivedTemplates}
                            />
                          </div>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                </div>
              </div>
              {!showNoMatchesState && (
                <>
                  <TabsContent value='template'>
                    <div className='rounded-lg flex flex-col gap-6'>
                      <div className='flex flex-col gap-4 h-full'>
                        <span className='font-bold text-grey-s6'>Performance</span>

                        <div className='grid grid-cols-[144px_1fr_1fr] gap-4'>
                          {/* First column */}
                          <div className='space-y-4'>
                            <div className='bg-white p-4 rounded-lg flex flex-col'>
                              <div className='text-xl font-bold'>
                                {taskAnalyticsQuery.isLoading ? '\u00A0' : spaceCount}
                              </div>
                              <div className='text-xxs leading-[14px] font-normal text-grey-s5 m-0'>
                                Total Spaces
                              </div>
                            </div>
                            <div className='bg-white p-4 rounded-lg flex flex-col'>
                              <div className='text-xl font-bold m-0'>
                                {taskAnalyticsQuery.isLoading ? '\u00A0' : spacesCompletedCount}
                              </div>
                              <div className='text-xxs leading-[14px] font-normal text-grey-s5 m-0'>
                                Total Spaces completed
                              </div>
                            </div>
                          </div>

                          {/* Second column */}
                          <div className='flex flex-col bg-white rounded-lg gap-4 p-4'>
                            <div className='flex justify-between items-center'>
                              <div className='flex flex-col'>
                                <span className='text-xl font-bold'>
                                  {taskAnalyticsQuery.isLoading ? '\u00A0' : totalTasksCompleted}
                                </span>
                                <span className='text-xxs leading-[14px] font-normal text-grey-s5'>
                                  Total tasks completed
                                </span>
                              </div>

                              <div className='flex items-center'>
                                <Select
                                  value={groupBy}
                                  onValueChange={(value: TTasksCompletedOverTimeGroupBy) =>
                                    setGroupBy(value)
                                  }
                                >
                                  <SelectTrigger className='w-[88px]'>
                                    <SelectValue placeholder='Group by' />
                                  </SelectTrigger>
                                  <SelectContent align='end'>
                                    <SelectItem value='week'>Week</SelectItem>
                                    <SelectItem value='month'>Month</SelectItem>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                            <TaskCompletionChart groupBy={groupBy} filter={filter} />
                          </div>

                          <div className='flex flex-col  gap-4 bg-white rounded-lg p-4'>
                            <div className='flex justify-between flex-col'>
                              <span className='text-xl font-bold'>
                                {taskAnalyticsQuery.isLoading ? '\u00A0' : totalAvgCompletionDays}
                              </span>
                              <span className='text-xxs leading-[14px] font-normal text-grey-s5'>
                                Average days to complete a space
                              </span>
                            </div>
                            {!taskAnalyticsQuery.isLoading && (
                              <AverageCompletionDaysChart data={taskAnalytics.items ?? []} />
                            )}
                            {taskAnalyticsQuery.isLoading && (
                              <Skeleton className='h-[144px] rounded-lg min-w-[300px]' />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={analyticsTableGridClass}>
                        <ActionPlanAnalyticsTableHeader
                          startLabel='Template Name'
                          className='bg-white rounded-t-lg border-t border-l border-r border-grey-s2'
                        />
                        <ActionPlanAnalyticsTableGroup
                          isLoading={taskAnalyticsQuery.isLoading}
                          searchTerm={search}
                          templateTitle={undefined}
                          tables={[
                            {
                              id: 'template',
                              column1Label: 'Template Name',
                              hideHeaderRow: true,
                              rows:
                                taskAnalytics?.items?.map((template) => ({
                                  id: template.templateId,
                                  originNodeId: template.templateNodeId,
                                  title: template.title,
                                  spaceCount: template.spaceCount,
                                  completedCount: template.completedCount,
                                  avgCompletionDays: template.avgCompletionDays,
                                  lowerQuartileDays: template.lowerQuartileDays,
                                  upperQuartileDays: template.upperQuartileDays,
                                  // Removed is always false for the template level (if template/archived
                                  // it does not appear in the analytics at all)
                                  removed: false,
                                  handleOpenDetailsClick: () => {
                                    setSearch('')
                                    setGroupingLevel('action plan')
                                    setTemplateIds(new Set([template.templateId]))
                                  },
                                  handleCellClick,
                                })) || [],
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </TabsContent>
                  <TabsContent value='action plan'>
                    <div className={analyticsTableGridClass}>
                      <ActionPlanAnalyticsTableHeader className='bg-background' />
                      {taskAnalyticsQuery.isLoading && (
                        <div
                          className={cn(
                            'rounded-lg bg-white/65 grid grid-cols-subgrid col-span-full mb-4 border border-grey-s2',
                          )}
                        >
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg w-[118px]' />
                          </div>
                        </div>
                      )}
                      {taskAnalytics?.items?.map((template) => (
                        <ActionPlanAnalyticsTableGroup
                          isLoading={taskAnalyticsQuery.isLoading}
                          key={template.templateId}
                          actionPlanBlockId={template.templateId}
                          searchTerm={groupingLevel === 'action plan' ? search : ''}
                          actionPlanTitle={template.title}
                          tables={[
                            {
                              id: template.templateId,
                              column1Label: 'Action Plan Name',
                              rows: template.actionPlans.map((actionPlan) => ({
                                id: actionPlan.originNodeId,
                                originNodeId: actionPlan.originNodeId,
                                title: actionPlan.title,
                                spaceCount: actionPlan.spaceCount,
                                completedCount: actionPlan.completedCount,
                                avgCompletionDays: actionPlan.avgCompletionDays,
                                lowerQuartileDays: actionPlan.lowerQuartileDays,
                                upperQuartileDays: actionPlan.upperQuartileDays,
                                removed: actionPlan.removed,
                                handleOpenDetailsClick: () => {
                                  setSearch('')
                                  setGroupingLevel('milestone')
                                  setActionPlanIds(new Set([actionPlan.originNodeId]))
                                },
                                handleCellClick,
                              })),
                            },
                          ]}
                        />
                      ))}
                    </div>
                  </TabsContent>
                  <TabsContent value='milestone'>
                    <div className={analyticsTableGridClass}>
                      <ActionPlanAnalyticsTableHeader className='bg-background' />
                      {taskAnalyticsQuery.isLoading && (
                        <div
                          className={cn(
                            'rounded-lg bg-white/65 grid grid-cols-subgrid col-span-full mb-4 border border-grey-s2',
                          )}
                        >
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg w-[118px]' />
                          </div>
                        </div>
                      )}
                      {!taskAnalyticsQuery.isLoading &&
                        taskAnalytics?.items?.map((template) =>
                          template.actionPlans.map((actionPlan) => (
                            <ActionPlanAnalyticsTableGroup
                              isLoading={taskAnalyticsQuery.isLoading}
                              key={actionPlan.originNodeId}
                              searchTerm={groupingLevel === 'milestone' ? search : ''}
                              actionPlanTitle={actionPlan.title}
                              templateTitle={template.title}
                              actionPlanBlockId={actionPlan.originNodeId}
                              tables={[
                                {
                                  id: actionPlan.originNodeId,
                                  column1Title: undefined,
                                  column1Label: 'Milestone Name',
                                  rows: actionPlan.milestones.map((milestone) => ({
                                    id: milestone.originNodeId,
                                    originNodeId: milestone.originNodeId,
                                    title: milestone.title,
                                    spaceCount: milestone.spaceCount,
                                    completedCount: milestone.completedCount,
                                    avgCompletionDays: milestone.avgCompletionDays,
                                    lowerQuartileDays: milestone.lowerQuartileDays,
                                    upperQuartileDays: milestone.upperQuartileDays,
                                    removed: milestone.removed,
                                    handleOpenDetailsClick: () => {
                                      setSearch('')
                                      setGroupingLevel('task')
                                      setMilestoneIds(new Set([milestone.originNodeId]))
                                    },
                                    handleCellClick,
                                  })),
                                },
                              ]}
                            />
                          )),
                        )}
                    </div>
                  </TabsContent>
                  <TabsContent value='task'>
                    <div className={analyticsTableGridClass}>
                      <ActionPlanAnalyticsTableHeader className='bg-background' />
                      {taskAnalyticsQuery.isLoading && (
                        <div
                          className={cn(
                            'rounded-lg bg-white/65 grid grid-cols-subgrid col-span-full mb-4 border border-grey-s2',
                          )}
                        >
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg w-[118px]' />
                          </div>
                        </div>
                      )}
                      {!taskAnalyticsQuery.isLoading &&
                        taskAnalytics?.items?.map((template) =>
                          template.actionPlans.map((actionPlan) => (
                            <ActionPlanAnalyticsTableGroup
                              isLoading={taskAnalyticsQuery.isLoading}
                              searchTerm={groupingLevel === 'task' ? search : ''}
                              key={actionPlan.originNodeId}
                              actionPlanTitle={actionPlan.title}
                              templateTitle={template.title}
                              actionPlanBlockId={actionPlan.originNodeId}
                              tables={actionPlan.milestones.map((milestone) => ({
                                id: milestone.originNodeId,
                                column1Title: `Milestone: ${milestone.title}`,
                                column1Label: 'Task Name',
                                defaultOpenActionLabel: 'Open Action Plan',
                                rows: milestone.tasks.map((task) => ({
                                  id: task.originNodeId,
                                  originNodeId: task.originNodeId,
                                  title: task.title,
                                  spaceCount: task.spaceCount,
                                  completedCount: task.completedCount,
                                  avgCompletionDays: task.avgCompletionDays,
                                  lowerQuartileDays: task.lowerQuartileDays,
                                  upperQuartileDays: task.upperQuartileDays,
                                  removed: task.removed,
                                  openActionLabel: task.removed ? 'Open Template' : undefined,
                                  openActionTrailingIcon: 'open-outline',
                                  handleOpenDetailsClick: () => {
                                    const url = new URL(location.origin)
                                    url.pathname = `/spaces/${template.templateNodeId}`
                                    if (!task.removed) {
                                      url.searchParams.set('block', actionPlan.originNodeId)
                                    }
                                    window.open(url.toString(), '_blank', 'noopener,noreferrer')
                                  },
                                  handleCellClick,
                                })),
                              }))}
                            />
                          )),
                        )}
                    </div>
                  </TabsContent>
                </>
              )}
            </Tabs>
            {showNoMatchesState && (
              <div className='flex flex-grow justify-center items-center'>
                <EmptyState icon={'search'}>
                  <EmptyStateHeader>
                    <EmptyStateTitle>No matches found!</EmptyStateTitle>
                    <EmptyStateDescription>
                      Try changing or clearing out your filters.
                    </EmptyStateDescription>
                  </EmptyStateHeader>
                  <EmptyStateAction
                    onClick={() => {
                      setTemplateIds(undefined)
                      setActionPlanIds(undefined)
                      setMilestoneIds(undefined)
                      setSearch('')
                      setOwnerIds(undefined)
                      setIncludeArchivedSpaces(true)
                      setIncludeArchivedTemplates(false)
                      handleTimePeriodSelection('Last 3 months')
                    }}
                  >
                    Reset filter
                  </EmptyStateAction>
                </EmptyState>
              </div>
            )}
            {typeof taskAnalytics?.meta?.lastRefreshed !== 'undefined' && (
              <>
                {!!flagsQuery.flags?.['dashboard-action-plan-analytics-refresh'] && (
                  <div className='flex justify-end pb-7'>
                    <Button2
                      size={'small'}
                      variant={'ghost'}
                      onClick={() => refreshMutation.mutate()}
                    >
                      Last refresh:{' '}
                      {taskAnalytics?.meta?.lastRefreshed
                        ? DateTime.fromISO(taskAnalytics.meta.lastRefreshed).toLocaleString(
                            DateTime.DATETIME_SHORT,
                          )
                        : 'never'}
                    </Button2>
                  </div>
                )}
                {!flagsQuery.flags?.['dashboard-action-plan-analytics-refresh'] && (
                  <div className='flex justify-end pb-7 text-xxs text-grey-s5 font-normal'>
                    <div>
                      Last refresh:{' '}
                      {taskAnalytics?.meta?.lastRefreshed
                        ? DateTime.fromISO(taskAnalytics.meta.lastRefreshed).toLocaleString(
                            DateTime.DATETIME_SHORT,
                          )
                        : 'never'}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ActionPlanAnalytics
