import { useMutation, useQueryClient } from '@tanstack/react-query'
import SellerApi from '../../../api/SellerApi'
import { OnboardTenantInput } from '@valuecase/common'

const onboardTenant = async (data: OnboardTenantInput) => {
  const response = await SellerApi.patch('/current-tenant/onboard', data)

  return response
}

interface UseOnboardTenantProps {
  onSuccess?: () => void
  onError?: () => void
}

export const useOnboardTenant = (props?: UseOnboardTenantProps) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: onboardTenant,
    onSuccess: (_, variables) => {
      // Invalidate tenant queries
      // If error was passed in, don't invalidate as we want the user to see the error state and click on the button to go to dashboard
      if (!('error' in variables)) {
        queryClient.invalidateQueries({ queryKey: ['tenant'] })
      }
      props?.onSuccess?.()
    },
    onError: () => {
      props?.onError?.()
    },
  })

  return {
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    error: mutation.error,
    onboardTenant: mutation.mutate,
    onboardTenantAsync: mutation.mutateAsync,
  }
}
