import { TTemplateGenerationRequestStatus } from '@valuecase/common'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const CREEP_ANIMATION_INTERVAL = 200
const STAGE_ANIMATION_DURATION = 800
const CREEP_INCREMENT = 0.1
const STAGE_BUFFER = 3

export const progressStages = {
  [TTemplateGenerationRequestStatus.PREVIEW]: 5,
  [TTemplateGenerationRequestStatus.STARTED]: 20,
  [TTemplateGenerationRequestStatus.TEMPLATE_GENERATOR_RESPONSE_RECEIVED]: 40,
  [TTemplateGenerationRequestStatus.BLOCK_CREATOR_RESPONSE_RECEIVED]: 70,
  [TTemplateGenerationRequestStatus.OUTPUT_VALIDATION]: 85,
  [TTemplateGenerationRequestStatus.COMPLETED]: 100,
} as const

export const useTemplateGenerationProgress = (
  status: TTemplateGenerationRequestStatus | undefined,
) => {
  const [currentStageIndex, setCurrentStageIndex] = useState(0)
  const lastStatusRef = useRef<TTemplateGenerationRequestStatus | null>(null)
  const lastProgressRef = useRef<number>(0)

  const stageKeys = useMemo(
    () => [
      TTemplateGenerationRequestStatus.PREVIEW,
      TTemplateGenerationRequestStatus.STARTED,
      TTemplateGenerationRequestStatus.TEMPLATE_GENERATOR_RESPONSE_RECEIVED,
      TTemplateGenerationRequestStatus.BLOCK_CREATOR_RESPONSE_RECEIVED,
      TTemplateGenerationRequestStatus.OUTPUT_VALIDATION,
      TTemplateGenerationRequestStatus.COMPLETED,
    ],
    [],
  )

  useEffect(() => {
    if (!status || status === lastStatusRef.current) return

    const newStageIndex = stageKeys.indexOf(status)
    if (newStageIndex > currentStageIndex) {
      setCurrentStageIndex(newStageIndex)
      lastProgressRef.current =
        progressStages[stageKeys[currentStageIndex] as keyof typeof progressStages]
    }
    lastStatusRef.current = status
  }, [status, stageKeys, currentStageIndex])

  const currentStage = stageKeys[currentStageIndex]
  const targetProgress = progressStages[currentStage as keyof typeof progressStages]

  const [currentProgress, setCurrentProgress] = useState(0)
  const creepAnimationRef = useRef<NodeJS.Timeout>()
  const stageAnimationRef = useRef<NodeJS.Timeout>()
  const lastTargetRef = useRef(targetProgress)
  const currentProgressRef = useRef(currentProgress)

  const getNextStageTarget = useCallback((currentTarget: number) => {
    const stagePercentages = Object.values(progressStages).sort((a, b) => a - b)
    const nextStageIndex = stagePercentages.findIndex((p) => p > currentTarget)
    return nextStageIndex === -1 ? currentTarget : stagePercentages[nextStageIndex] - STAGE_BUFFER
  }, [])

  const startCreep = useCallback(() => {
    if (creepAnimationRef.current) {
      clearTimeout(creepAnimationRef.current)
    }

    const creep = () => {
      const nextStageTarget = getNextStageTarget(targetProgress)
      const remaining = nextStageTarget - currentProgressRef.current

      if (remaining > 0) {
        setCurrentProgress((prev) => prev + CREEP_INCREMENT)
        creepAnimationRef.current = setTimeout(creep, CREEP_ANIMATION_INTERVAL)
      }
    }

    creep()
  }, [targetProgress, getNextStageTarget])

  useEffect(() => {
    currentProgressRef.current = currentProgress
  }, [currentProgress])

  useEffect(() => {
    const clearAnimations = () => {
      if (creepAnimationRef.current) clearTimeout(creepAnimationRef.current)
      if (stageAnimationRef.current) clearTimeout(stageAnimationRef.current)
    }

    clearAnimations()

    if (targetProgress > lastTargetRef.current) {
      const startProgress = currentProgressRef.current
      const progressDiff = targetProgress - startProgress
      const startTime = Date.now()

      const animateToStage = () => {
        const elapsed = Date.now() - startTime
        const progress = Math.min(elapsed / STAGE_ANIMATION_DURATION, 1)
        const easeOutQuart = (t: number) => 1 - Math.pow(1 - t, 4)
        const currentValue = startProgress + progressDiff * easeOutQuart(progress)

        setCurrentProgress(currentValue)

        if (progress < 1) {
          stageAnimationRef.current = setTimeout(animateToStage, 16)
        } else {
          startCreep()
        }
      }

      animateToStage()
      lastTargetRef.current = targetProgress
    } else {
      startCreep()
    }

    return clearAnimations
  }, [targetProgress, startCreep])

  return Math.min(Math.max(0, currentProgress), 100)
}
