import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { useQuery } from '@tanstack/react-query'
import { TTemplateGenerationRequest } from '@valuecase/common'

const fetchActiveTemplateGeneration = async () => {
  const response = await spacesApi.get<TTemplateGenerationRequest>(
    '/templates/generate/ai/active-request',
  )
  return response.data
}

/**
 * This hook fetches the latest active template generation request for the user. Active is defined as not COMPLETED or in ERROR state.
 * It is used to persist the template generation loading bar and allow continued polling.
 */
export const useGetActiveTemplateGeneration = () => {
  return useQuery({
    queryKey: ['activeTemplateGeneration'],
    queryFn: fetchActiveTemplateGeneration,
    refetchOnWindowFocus: false,
    retry: false,
  })
}
