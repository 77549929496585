import { useS3Upload } from '@/api/s3'
import { useSignedImageUrl } from '@/api/spacesApi/signedS3Images'
import { useAuthState } from '@/auth/auth'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { useGetCompanyMetadata } from '@/modules/company/hooks/useGetCompanyMetadata'
import { SpacePreviewRender } from '@/modules/companySettings/interactiveSpacePreview/SpacePreviewRender'
import { fetchLogoFromUrl } from '@/utils/getLogoFromUrl'
import { CompanyInfo } from '@valuecase/common'
import { Button2, Divider, Input2, useNotifications } from '@valuecase/ui-components'
import { InputColorPicker } from '@valuecase/ui-components/src/components/ui/color-picker'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { generateGradientImage } from '../generateGradientImage'
import { TSelfSignUp } from '../types/TSelfSignUp'

type TenantSetupStepProps = {
  onUpdateForm: (field: keyof TSelfSignUp, value: any) => void
  form: TSelfSignUp
}

const TenantSetupStep = ({ onUpdateForm, form }: TenantSetupStepProps) => {
  const { trackEvent } = useTrackEvent()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { upload } = useS3Upload()
  const { error: toastError } = useNotifications()
  const authState = useAuthState()
  const userId = useMemo(() => (authState.authorized ? authState.sub : undefined), [authState])
  const { imageUrl: companyLogoUrl } = useSignedImageUrl({
    s3Key: form.logoImage?.s3Key ?? '',
    source: 'S3',
  })
  const [lastSetWebsiteUrl, setLastSetWebsiteUrl] = useState(form.websiteUrl)
  const { imageUrl: backgroundImageUrl } = useSignedImageUrl(
    form.backgroundImage
      ? {
          s3Key: form.backgroundImage?.s3Key ?? '',
          source: 'S3',
        }
      : null,
  )
  const [previewIsLoading, setPreviewIsLoading] = useState(false)

  const { getCompanyMetadataAsync } = useGetCompanyMetadata()
  const debouncedTrackEvent = useCallback(
    (eventName: string) => {
      const debouncedFn = debounce((name: string) => {
        trackEvent({ event: name })
      }, 1000)
      debouncedFn(eventName)
    },
    [trackEvent],
  )

  const handleCompanyChange = useCallback(
    (value: string) => {
      onUpdateForm('tenantName', value)
      debouncedTrackEvent('self_sign_up-start-edit_company')
    },
    [onUpdateForm, debouncedTrackEvent],
  )

  const updateBackgroundImageBasedOnColor = useCallback(
    async (color: string) => {
      try {
        const gradientFile = await generateGradientImage(color)
        const uploadedFile = await upload(gradientFile)

        onUpdateForm('backgroundImage', {
          source: 'S3',
          s3Key: uploadedFile.s3ObjectKey,
        })
      } catch (error) {
        console.error('Failed to generate or upload gradient:', error)
      }
    },
    [onUpdateForm, upload],
  )

  const handleColorChange = useCallback(
    async (value: string) => {
      onUpdateForm('primaryColor', value)
      updateBackgroundImageBasedOnColor(value)
      debouncedTrackEvent('self_sign_up-start-edit_color')
    },
    [onUpdateForm, updateBackgroundImageBasedOnColor, debouncedTrackEvent],
  )

  const handleUploadClick = useCallback(() => {
    fileInputRef?.current?.click()
  }, [])

  const handleUploadLogo = useCallback(
    async (file: File) => {
      const uploadedFile = await upload(file)
      trackEvent({
        event: 'self_sign_up-start-edit_logo',
        eventProperties: {
          method: 'upload',
          userId,
        },
      })

      const logoImageData = {
        source: 'S3',
        s3Key: uploadedFile.s3ObjectKey,
      }

      onUpdateForm('logoImage', logoImageData)
    },
    [upload, trackEvent, userId, onUpdateForm],
  )

  const handleDeleteLogo = useCallback(() => {
    onUpdateForm('logoImage', '')
    trackEvent({
      event: 'self_sign_up-start-edit_logo',
      eventProperties: {
        method: 'delete',
        userId,
      },
    })
  }, [onUpdateForm, trackEvent, userId])

  const handleCompanyMetadataPayload = useCallback(
    async (data: CompanyInfo) => {
      if (data.accentColor?.hex) {
        onUpdateForm('primaryColor', data.accentColor.hex)
        updateBackgroundImageBasedOnColor(data.accentColor.hex)
      }

      if (data.name) {
        onUpdateForm('tenantName', data.name)
      }
    },
    [onUpdateForm, updateBackgroundImageBasedOnColor],
  )

  const handleWebsiteChange = useCallback(
    async (website: string) => {
      const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/

      if (website === lastSetWebsiteUrl) {
        return
      }

      if (urlRegex.test(website)) {
        // Remove www. and trailing slashes because Brandfetch does not like them.
        const strippedWebsite = website.replace(/^https?:\/\/(www\.)?/, '').replace(/\/$/, '')

        try {
          setPreviewIsLoading(true)
          onUpdateForm('logoImage', null)
          const metadata = await getCompanyMetadataAsync(strippedWebsite)

          if (!metadata) {
            toastError('Failed to retrieve company details. Please try again or update manually.')
            setPreviewIsLoading(false)
            return
          }

          const logoData = await fetchLogoFromUrl(strippedWebsite)

          // Use frontend brand fetch logo because the response we get on the backend contains multiple logos
          // and sometimes the first in the array does not work.
          if (logoData) {
            const uploadedFile = await upload(logoData.file)
            onUpdateForm('logoImage', {
              source: 'S3',
              s3Key: uploadedFile.s3ObjectKey,
            })
          }
          await handleCompanyMetadataPayload(metadata)
          setLastSetWebsiteUrl(website)
          setPreviewIsLoading(false)
        } catch (error) {
          toastError('Failed to retrieve company details. Please try again or update manually.')
          setPreviewIsLoading(false)
          console.error('Failed to fetch company metadata:', error)
        }
      }
    },
    [
      lastSetWebsiteUrl,
      onUpdateForm,
      getCompanyMetadataAsync,
      handleCompanyMetadataPayload,
      upload,
      toastError,
    ],
  )

  useEffect(() => {
    if (form.primaryColor && !form.backgroundImage) {
      updateBackgroundImageBasedOnColor(form.primaryColor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='h-fit w-full bg-white/50 rounded-lg p-8 flex gap-16'>
      <div className='flex flex-col gap-6 w-full'>
        <div className='flex flex-col w-full gap-3'>
          <div className='flex w-full '>
            <div className='flex-1 flex flex-col gap-1 justify-center'>
              <span className='text-grey-s6 text-sm font-semibold'>Company Website</span>
            </div>
            <div className='flex-1 flex items-start justify-end'>
              <Input2
                className='w-full'
                value={form.websiteUrl}
                onChange={(e) => {
                  onUpdateForm('websiteUrl', e.target.value)
                }}
                onBlur={(e) => handleWebsiteChange(e.target.value)}
              />
            </div>
          </div>
          <span className='text-grey-s5 text-xs'>
            Your company details are only used to create a custom-branded Space just for you.
          </span>
        </div>

        <Divider />

        <div className='flex w-full gap-4'>
          <div className='flex-1'>
            <span className='text-grey-s6 text-sm font-semibold'>Your Company Name</span>
          </div>
          <div className='flex-1 flex justify-end'>
            <Input2
              className='w-full'
              value={form.tenantName}
              onChange={(e) => handleCompanyChange(e.target.value)}
            />
          </div>
        </div>

        <div className='flex w-full gap-4'>
          <div className='flex-1'>
            <span className='text-grey-s6 text-sm font-semibold'>Accent Color</span>
          </div>
          <div className='flex-1 flex justify-end'>
            <InputColorPicker value={form.primaryColor} onColorChange={handleColorChange} />
          </div>
        </div>

        <div className='flex w-full gap-4'>
          <div className='flex-1'>
            <span className='text-grey-s6 text-sm font-semibold'>Company Logo</span>
          </div>
          <div className='flex-1 flex justify-end'>
            {!form.logoImage ? (
              <Button2
                leadingIcon='cloud-upload-outline'
                onClick={() => {
                  trackEvent({
                    event: 'self_sign_up-start-edit_logo',
                    eventProperties: {
                      method: 'click_upload',
                    },
                  })
                  handleUploadClick()
                }}
              >
                Upload
              </Button2>
            ) : (
              <div className='flex gap-3 items-center'>
                <div className='w-12 h-12 overflow-hidden rounded-lg border border-grey-s2 bg-grey-s1'>
                  <img
                    src={companyLogoUrl}
                    alt='company logo'
                    className='w-full h-full object-contain'
                  />
                </div>
                <Button2 variant='danger' leadingIcon='trash-outline' onClick={handleDeleteLogo}>
                  Delete
                </Button2>
              </div>
            )}
          </div>
        </div>
      </div>

      <input
        ref={fileInputRef}
        type='file'
        className='hidden'
        multiple={false}
        accept='image/*'
        onChange={(e) => {
          e.preventDefault()
          const file = e.target.files?.[0]
          if (file) {
            handleUploadLogo(file)
          }
        }}
      />

      <div className='flex flex-col w-fit h-full items-center gap-3'>
        <SpacePreviewRender
          backgroundImageUrl={backgroundImageUrl}
          primaryColor={form.primaryColor}
          companyLogoUrl={companyLogoUrl}
          theme={form.theme as 'Dark' | 'Light'}
          isLoading={previewIsLoading}
        />
        <span className='text-xs text-grey-s5'>Preview of your first Valuecase Space.</span>
      </div>
    </div>
  )
}

export default TenantSetupStep
