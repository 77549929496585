import React, { useCallback, useRef } from 'react'
import BaseModal, { ModalControls } from '../base/BaseModal'
import styled from 'styled-components'
import { Button } from '../../Button'

export interface ComplexModalProps {
  header?: React.ReactNode
  body?: React.ReactNode
  persistent?: boolean
  wide?: boolean
  showBack?: boolean
  onBack?: (controls: ModalControls) => void
  hideClose?: boolean
  controlsRef?: React.MutableRefObject<ModalControls | null> | ((controls: ModalControls) => void)
  className?: string
}
/**
 * @deprecated use Dialog
 */
export function ComplexModal({
  header,
  body,
  persistent,
  wide,
  showBack = false,
  onBack,
  hideClose = false,
  controlsRef,
  className,
}: ComplexModalProps) {
  const controls = useRef<ModalControls | null>(null)

  const controlsCallback = useCallback(
    (newControls: ModalControls) => {
      controls.current = newControls

      if (controlsRef) {
        if (typeof controlsRef === 'function') {
          controlsRef(newControls)
        } else {
          controlsRef.current = newControls
        }
      }
    },
    [controlsRef],
  )

  return (
    <BaseModal
      controlsRef={controlsCallback}
      persistent={persistent}
      wide={wide}
      className={className}
    >
      {(showBack || !hideClose || header) && (
        <ComplexHeaderContainer>
          <div
            style={{
              paddingLeft: showBack ? '16px' : '24px',
              paddingRight: !hideClose ? '16px' : '24px',
            }}
          >
            {showBack && (
              <Button
                icon='arrow-back'
                size={'medium'}
                style={'flat'}
                onClick={() => {
                  if (onBack && controls.current) {
                    onBack(controls.current)
                  } else {
                    controls.current?.close()
                  }
                }}
              />
            )}

            <div style={{ flexGrow: '1', width: '0', overflowX: 'hidden' }}>{header}</div>

            {!hideClose ? (
              <Button
                icon='close'
                size={'medium'}
                style={'flat'}
                onClick={() => {
                  controls.current?.close()
                }}
              />
            ) : (
              <span />
            )}
          </div>
        </ComplexHeaderContainer>
      )}

      <ComplexBodyContainer>{body}</ComplexBodyContainer>
    </BaseModal>
  )
}

const ComplexHeaderContainer = styled.div`
  width: 100%;

  background: var(--theme-light);
  border-bottom: 1px solid var(--theme-grey-s2);

  > div:nth-child(1) {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
  }
`

const ComplexBodyContainer = styled.div`
  background: var(--theme-light);
`
