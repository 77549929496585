import TeamSelector from '@/modules/teams/TeamSelector'
import { TeamUnion } from '@valuecase/common'
import { Button2, Input2, Hairline, cn } from '@valuecase/ui-components'
import { useCallback, useMemo, useState } from 'react'
import FilesDropArea from './FilesDropArea'
import FilesDropAreaBrowse from './FilesDropAreaBrowse'
import { Step1DataType } from './SpaceCreationFlow'

type SpaceCreationForm = {
  values: Step1DataType
  logoUrl: string
  onUpdateForm: (key: keyof Step1DataType, value: string | File | null) => void
  setLogoUrl: React.Dispatch<React.SetStateAction<string>>
  getLogoFromUrl: () => Promise<void>
  handleUploadFile: (file: File) => Promise<void>
  selectedTeamIds?: Set<string>
  setSelectedTeamIds?: (teamIds: Set<string> | undefined) => void
  teams?: TeamUnion[]
  tenantName?: string
  className?: string
}
const httpRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

export function SpaceCreationForm({
  values,
  onUpdateForm,
  logoUrl,
  setLogoUrl,
  handleUploadFile,
  getLogoFromUrl,
  selectedTeamIds,
  setSelectedTeamIds,
  teams = [],
  tenantName = '',
  className,
}: SpaceCreationForm) {
  const [websiteError, setWebsiteError] = useState('')
  const hasTeams = useMemo(() => teams.length > 0, [teams])

  const handleWebsiteInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLogoUrl(e.target.value)
      setWebsiteError('')
    },
    [setLogoUrl],
  )

  const isWebsiteValid = useCallback(() => {
    let url = logoUrl
    if (!url.includes('http://') && !url.includes('https://')) {
      url = `https://${url}`
    }
    return httpRegex.test(url)
  }, [logoUrl])

  const handleWebsiteInputBlur = useCallback(async () => {
    if (logoUrl && !isWebsiteValid()) {
      setWebsiteError('Please input a correct URL')
      return
    }

    if (logoUrl) {
      await getLogoFromUrl()
    }
  }, [isWebsiteValid, logoUrl, getLogoFromUrl])

  const handleDeleteLogo = useCallback(() => {
    onUpdateForm('logoBlob', null)
  }, [onUpdateForm])

  return (
    <div className={cn('w-full flex flex-col', className)}>
      <div className='h-[406px] -ml-8 w-[calc(100%+64px)]'>
        <Hairline />
        <div className='p-6 h-[406px] bg-grey-s1 flex flex-col gap-4'>
          {hasTeams && setSelectedTeamIds && (
            <div className='flex items-center w-full'>
              <div className='w-[27.15%] flex items-center'>
                <span className='text-sm font-semibold text-grey-s6'>Team access</span>
              </div>
              <div className='flex-1'>
                <TeamSelector
                  selectedTeamIds={selectedTeamIds}
                  teams={teams}
                  setSelectedTeamIds={setSelectedTeamIds}
                  tenantName={tenantName}
                  triggerButtonSize='default'
                />
              </div>
            </div>
          )}

          <div className='flex items-center w-full'>
            <div className='w-[27.15%] flex items-center'>
              <span className='text-sm font-semibold text-grey-s6'>Company name</span>
            </div>
            <div className='flex-1'>
              <Input2
                data-intercom-target='Company Name Input Field'
                placeholder='Enter company name'
                value={values.companyName}
                onChange={(e) => onUpdateForm('companyName', e.target.value)}
              />
            </div>
          </div>

          <div className='flex items-center w-full'>
            <div className='w-[27.15%] flex items-center'>
              <span className='text-sm font-semibold text-grey-s6'>Contact name</span>
            </div>
            <div className='flex-1 flex gap-2'>
              <Input2
                data-intercom-target='First Name Input Field'
                placeholder='First name'
                value={values.contactFirstName}
                onChange={(e) => onUpdateForm('contactFirstName', e.target.value)}
              />
              <Input2
                data-intercom-target='Last Name Input Field'
                placeholder='Last name'
                value={values.contactLastName}
                onChange={(e) => onUpdateForm('contactLastName', e.target.value)}
              />
            </div>
          </div>

          <div className='flex w-full'>
            <div className='w-[27.15%] pt-3'>
              <span className='text-sm font-semibold text-grey-s6'>Company logo</span>
            </div>
            <div className='flex-1'>
              {!values.logoBlob ? (
                <div className='flex gap-6 items-center'>
                  <div className='h-[178px] w-[264px] max-w-[40%]'>
                    <FilesDropArea fillParent onFilesDropped={handleUploadFile}>
                      <FilesDropAreaBrowse
                        supportedMimeTypes={'image/*'}
                        supportedText={'PNG, JPG, SVG'}
                        onFilesSelected={handleUploadFile}
                        allowMultipleFiles={false}
                      />
                    </FilesDropArea>
                  </div>
                  <div className='w-[1px] h-full bg-grey-s2' />
                  <div className='flex gap-2 items-end'>
                    <div className='flex max-w-[242px] flex-col relative'>
                      <p className='text-sm text-grey-s5 mb-4'>
                        <span className='font-bold'>Or</span> enter your stakeholder&apos;s website
                        URL to load the logo automatically.
                      </p>
                      <Input2
                        data-intercom-target='Insert Link Input Field'
                        placeholder='Insert link'
                        value={logoUrl}
                        onChange={handleWebsiteInput}
                        onBlur={handleWebsiteInputBlur}
                      />
                      {websiteError && (
                        <p className='absolute text-warning-s5 bottom-0 translate-y-[calc(100%+8px)]'>
                          {websiteError}
                        </p>
                      )}
                    </div>
                    <Button2
                      variant='outlined'
                      trailingIcon='arrow-forward-outline'
                      onClick={getLogoFromUrl}
                      disabled={!isWebsiteValid() || !logoUrl}
                    >
                      Load logo
                    </Button2>
                  </div>
                </div>
              ) : (
                <div className='flex gap-4 items-end h-[178px]'>
                  <div className='w-[178px] h-[178px] overflow-hidden rounded-lg border border-grey-s2 bg-grey-s1'>
                    <img
                      src={URL.createObjectURL(values.logoBlob)}
                      alt='company logo'
                      className='w-full h-full object-contain'
                    />
                  </div>
                  <Button2
                    variant='outlined'
                    trailingIcon='trash-outline'
                    onClick={handleDeleteLogo}
                    className='text-warning-s5 border-warning-s5 hover:bg-warning-s2'
                  >
                    Delete logo
                  </Button2>
                </div>
              )}
            </div>
          </div>
        </div>
        <Hairline />
      </div>
    </div>
  )
}
