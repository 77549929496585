import { useState } from 'react'
import {
  Button2,
  cn,
  extractTitleFromQuillObject,
  Highlight,
  OneLineTruncatedText,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@valuecase/ui-components'
import { TTemplateSummarySpacesGroupType } from '@valuecase/common'

export const ActionPlanAnalyticsTableGroup = ({
  isLoading,
  searchTerm,
  actionPlanBlockId,
  actionPlanTitle,
  templateTitle,
  tables,
}: {
  isLoading: boolean
  searchTerm: string
  actionPlanBlockId?: string
  actionPlanTitle?: string
  templateTitle?: string
  tables: {
    id: string
    hideHeaderRow?: boolean
    className?: string
    column1Title?: string
    column1Label?: string
    defaultOpenActionLabel?: string
    rows: {
      id: string
      originNodeId: string
      title: string
      spaceCount?: number
      completedCount?: number
      avgCompletionDays?: number | null
      lowerQuartileDays?: number | null
      upperQuartileDays?: number | null
      removed?: boolean
      openActionLabel?: string
      openActionTrailingIcon?: string
      handleOpenDetailsClick?: () => void
      handleCellClick?: ({
        originNodeId,
        groupType,
      }: {
        originNodeId: string
        groupType: TTemplateSummarySpacesGroupType
      }) => void
    }[]
  }[]
}) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const useSections = typeof actionPlanTitle === 'string' || typeof templateTitle === 'string'

  return (
    <>
      {isLoading && (
        <div
          className={cn(
            'rounded-lg bg-white/65 grid grid-cols-subgrid col-span-full mb-4 border border-grey-s2 rounded-t-none',
          )}
        >
          <div className='p-4'>
            <Skeleton className='h-4 rounded-lg' />
          </div>
          <div className='p-4'>
            <Skeleton className='h-4 rounded-lg' />
          </div>
          <div className='p-4'>
            <Skeleton className='h-4 rounded-lg' />
          </div>
          <div className='p-4'>
            <Skeleton className='h-4 rounded-lg' />
          </div>
          <div className='p-4'>
            <Skeleton className='h-4 rounded-lg' />
          </div>
          <div className='p-4'>
            <Skeleton className='h-4 rounded-lg' />
          </div>
          <div className='p-4'>
            <Skeleton className='h-4 rounded-lg w-[118px]' />
          </div>
        </div>
      )}
      {!isLoading && (
        <div
          key={actionPlanBlockId}
          className={cn('rounded-lg bg-white/65 grid grid-cols-subgrid col-span-full mb-4', {
            'grid grid-cols-subgrid col-span-full gap-3 p-3': useSections,
          })}
        >
          {useSections && (
            <h3 className='text-xs font-semibold text-grey-s5 items-center gap-1 col-span-full'>
              <Button2
                className='text-primary-s5'
                variant={'plain'}
                trailingIcon={isExpanded ? 'chevron-down' : 'chevron-up'}
                size={'x-small'}
                onClick={() => setIsExpanded(!isExpanded)}
              />
              <span className='inline-flex gap-1'>
                {typeof actionPlanTitle === 'string' &&
                  extractTitleFromQuillObject(actionPlanTitle)}
                <span className='text-grey-s4 font-normal'>
                  {typeof templateTitle === 'string' && extractTitleFromQuillObject(templateTitle)}
                </span>
              </span>
            </h3>
          )}
          {isExpanded &&
            tables.map((table) => (
              <div
                key={table.id}
                className={cn(
                  'w-full rounded border border-grey-s2 overflow-hidden grid grid-cols-subgrid col-span-full',
                  {
                    'rounded-t-none': table.hideHeaderRow,
                  },
                  table.className,
                )}
              >
                <Table className='w-full grid grid-cols-subgrid col-span-full'>
                  <TableHeader className='bg-[var(--theme-background)] grid grid-cols-subgrid col-span-full w-full'>
                    {!table.hideHeaderRow && (
                      <TableRow className='grid grid-cols-subgrid col-span-full w-full'>
                        <TableHead>
                          <span>{table.column1Label}</span>
                          <span className='block font-normal text-xxs text-grey-s5'>
                            {table.column1Title}
                          </span>
                        </TableHead>
                        <TableHead></TableHead>
                      </TableRow>
                    )}
                  </TableHeader>
                  <TableBody className='grid grid-cols-subgrid col-span-full w-full'>
                    {table.rows.map((row) => (
                      <TableRow
                        key={row.id}
                        className='grid grid-cols-subgrid col-span-full w-full'
                      >
                        <TableCell className='pt-2 pb-2 text-sm text-grey-s6 font-semibold'>
                          <Highlight searchTerm={searchTerm}>
                            <OneLineTruncatedText
                              className={cn({
                                'text-grey-s5 font-normal': row.removed,
                              })}
                            >
                              <span>{row.removed ? '(Removed) ' : ''}</span>
                              <span>{extractTitleFromQuillObject(row.title)}</span>
                            </OneLineTruncatedText>
                          </Highlight>
                        </TableCell>
                        <TableCell
                          className={cn('pt-2 pb-2', {
                            'cursor-pointer hover:bg-primary-s2': row.spaceCount,
                          })}
                          onClick={
                            row.spaceCount
                              ? () =>
                                  row.handleCellClick?.({
                                    originNodeId: row.originNodeId,
                                    groupType: 'space count',
                                  })
                              : undefined
                          }
                        >
                          {row.spaceCount}
                        </TableCell>
                        <TableCell
                          className={cn('pt-2 pb-2', {
                            'cursor-pointer hover:bg-primary-s2': !!row.completedCount,
                          })}
                          onClick={
                            row.completedCount
                              ? () =>
                                  row.handleCellClick?.({
                                    originNodeId: row.originNodeId,
                                    groupType: 'completed',
                                  })
                              : undefined
                          }
                        >
                          {typeof row.completedCount === 'number' && row.spaceCount
                            ? `${Math.round((row.completedCount / row.spaceCount) * 100)}%`
                            : '-'}
                        </TableCell>
                        <TableCell
                          className={cn('pt-2 pb-2', {
                            'cursor-pointer hover:bg-primary-s2':
                              typeof row.avgCompletionDays === 'number',
                          })}
                          onClick={
                            typeof row.avgCompletionDays === 'number'
                              ? () =>
                                  row.handleCellClick?.({
                                    originNodeId: row.originNodeId,
                                    groupType: 'days to completion',
                                  })
                              : undefined
                          }
                        >
                          {typeof row.avgCompletionDays === 'number'
                            ? row.avgCompletionDays.toFixed(1)
                            : '-'}
                        </TableCell>
                        <TableCell
                          className={cn('pt-2 pb-2', {
                            'cursor-pointer hover:bg-primary-s2':
                              typeof row.lowerQuartileDays === 'number',
                          })}
                          onClick={
                            typeof row.lowerQuartileDays === 'number'
                              ? () =>
                                  row.handleCellClick?.({
                                    originNodeId: row.originNodeId,
                                    groupType: 'quickest',
                                  })
                              : undefined
                          }
                        >
                          {typeof row.lowerQuartileDays === 'number' ? row.lowerQuartileDays : '-'}
                        </TableCell>
                        <TableCell
                          className={cn('pt-2 pb-2', {
                            'cursor-pointer hover:bg-primary-s2':
                              typeof row.upperQuartileDays === 'number',
                          })}
                          onClick={
                            typeof row.upperQuartileDays === 'number'
                              ? () =>
                                  row.handleCellClick?.({
                                    originNodeId: row.originNodeId,
                                    groupType: 'slowest',
                                  })
                              : undefined
                          }
                        >
                          {typeof row.upperQuartileDays === 'number' ? row.upperQuartileDays : '-'}
                        </TableCell>
                        <TableCell className='py-1'>
                          <Button2
                            onClick={row.handleOpenDetailsClick}
                            variant={'ghost'}
                            trailingIcon={row.openActionTrailingIcon || 'arrow-forward'}
                            className='h-7'
                          >
                            {row.openActionLabel || table.defaultOpenActionLabel || 'Open Details'}
                          </Button2>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ))}
        </div>
      )}
    </>
  )
}
