import { useAuthState } from '@/auth/auth'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { OnboardTenantInput, ValuecaseAuthState } from '@valuecase/common'
import { Button2, useBreakpoints } from '@valuecase/ui-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTemplateGeneration } from '../templates/TemplateCreator/TemplateGeneration/context/TemplateGenerationContext'
import {
  FlowStep,
  FullScreenFlow,
} from '../templates/TemplateCreator/TemplateGeneration/FullScreenFlow'
import { TemplateGenerationPromptStage } from '../templates/TemplateCreator/TemplateGeneration/stages/TemplateGenerationPromptStage'
import { useCurrentTenantQuery } from '../tenant/hooks/useReadTenant'
import CreateTemplateAndSpaceLoadingStep from './steps/CreateTemplateAndSpaceLoadingStep'
import { SpaceCreationStep } from './steps/SpaceCreationStep'
import TenantSetupStep from './steps/TenantSetupStep'
import { TSelfSignUp } from './types/TSelfSignUp'
import IntercomComponent from '../navigation/Intercom/Intercom'
import { useOnboardTenant } from '../tenant/hooks/useOnboardTenant'

export const OnboardingFlow = ({ isOpen }: { isOpen: boolean }) => {
  const { stageTemplateRequest, startTemplateGeneration } = useTemplateGeneration()
  const authState = useAuthState() as ValuecaseAuthState
  const [currentStep, setCurrentStep] = useState(0)
  const { tenant } = useCurrentTenantQuery()
  const tenantData = useMemo(() => tenant?.payload, [tenant?.payload])
  const { isMobile } = useBreakpoints()
  const [form, setForm] = useState<TSelfSignUp>({
    useCase: 'onboarding',
    theme: tenantData?.theme ?? 'light',
    context: '',

    // Tenant fields
    logoImage: tenantData?.logoImage,
    websiteUrl: tenantData?.websiteUrl ?? '',
    tenantName: tenantData?.tenantName ?? '',
    primaryColor: tenantData?.primaryColor ?? '#B98DDB',
    backgroundImage: tenantData?.backgroundImage,

    //SPACE FIELDS
    companyName: '',
    contactFirstName: '',
    contactLastName: '',
    // Space Logo to render
    logoBlob: null,
    // Space Logo to upload
    spaceLogo: '',
  })

  const { onboardTenantAsync } = useOnboardTenant()

  const userId = useMemo(() => (authState.authorized ? authState.sub : undefined), [authState])

  const { trackEvent } = useTrackEvent()

  useEffect(() => {
    trackEvent({
      event: 'self_sign_up-start',
      eventProperties: {
        includePageProperties: true,
        includeEventSource: true,
        userId,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCreateSpace = useCallback(() => {
    trackEvent({
      event: 'self_sign_up-space_creation-complete',
      eventProperties: {
        userId,
      },
    })
    setCurrentStep((prev) => prev + 1)
  }, [userId, trackEvent])

  const handleBack = useCallback(() => {
    setCurrentStep((prev) => Math.max(0, prev - 1))
  }, [])

  const handleUpdateForm = useCallback((field: keyof TSelfSignUp, value: string | File) => {
    setForm((prev) => ({ ...prev, [field]: value }))
  }, [])

  const tenantSettingsInputsSatisfied = useMemo(() => {
    return form.websiteUrl.length > 0 && form.tenantName.length > 0 && form.primaryColor.length > 0
  }, [form])

  const spaceCreationInputsSatisfied = useMemo(() => {
    return (
      form.companyName.length > 0 &&
      form.contactFirstName.length > 0 &&
      form.contactLastName.length > 0
    )
  }, [form])

  const templateGenerationInputsSatisfied = useMemo(() => {
    return form.context.length > 0 && form.useCase.length > 0
  }, [form])

  const handleCreateTemplateAndSpace = useCallback(async () => {
    try {
      // Initiating template generation is a two step process hence we call both in sequence.
      const generationRequest = await stageTemplateRequest({
        data: {
          useCase: form.useCase as 'onboarding' | 'sales' | 'other',
          context: form.context,
        },
        skipPreview: true,
        fallbackEnabled: true,
      })
      await startTemplateGeneration(generationRequest.id)
      trackEvent({
        event: 'self_sign_up-space_creation-visit',
        eventProperties: {
          includePageProperties: true,
          includeEventSource: true,
          userId,
        },
      })
      setCurrentStep((prev) => prev + 1)
    } catch (error) {
      console.error(error)
    }
  }, [
    form.context,
    form.useCase,
    stageTemplateRequest,
    startTemplateGeneration,
    userId,
    trackEvent,
  ])

  const handlePromptSelect = useCallback(
    (context: string) => {
      trackEvent({
        event: 'self_sign_up-ai_template-example_prompt',
        eventProperties: {
          userId,
          useCase: context,
        },
      })
    },
    [userId, trackEvent],
  )

  const handleUseCaseSelect = useCallback(
    (useCase: string) => {
      trackEvent({
        event: 'self_sign_up-ai_template-select_case',
        eventProperties: {
          'AI template use case': useCase,
          userId,
        },
      })
    },
    [userId, trackEvent],
  )

  const handlePromptChange = useCallback(
    (context: string) => {
      trackEvent({
        event: 'self_sign_up-ai_template-write_prompt',
        eventProperties: {
          context: context,
          userId,
        },
      })
    },
    [userId, trackEvent],
  )

  const handleOnboardTenant = useCallback(async () => {
    const payload: OnboardTenantInput = {
      websiteUrl: form.websiteUrl,
      tenantName: form.tenantName,
      primaryColor: form.primaryColor,
    }

    if (form.logoImage) {
      payload.logoImage = form.logoImage
    }

    if (form.backgroundImage) {
      payload.backgroundImage = form.backgroundImage
    }

    await onboardTenantAsync(payload)

    trackEvent({
      event: 'self_sign_up-ai_template-visit',
      eventProperties: {
        includePageProperties: true,
        includeEventSource: true,
        userId,
      },
    })
    setCurrentStep((prev) => prev + 1)
  }, [form, onboardTenantAsync, userId, trackEvent])

  const steps = useMemo<FlowStep[]>(
    () => [
      {
        id: 'tenant-settings',
        component: TenantSetupStep,
        icon: 'color-wand',
        title: "Welcome to Valuecase! Let's set up your brand.",
        titleSubtext:
          "We'll create your design based on your input below. No worries, you can change everything later.",
        rightButton: (
          <Button2
            trailingIcon='arrow-forward-outline'
            onClick={handleOnboardTenant}
            disabled={!tenantSettingsInputsSatisfied}
          >
            Next
          </Button2>
        ),
      },
      {
        id: 'template',
        icon: 'c_ai-sparkles',
        component: TemplateGenerationPromptStage,
        componentProps: {
          onPromptSelect: handlePromptSelect,
          onUseCaseSelect: handleUseCaseSelect,
          onPromptChange: handlePromptChange,
        },
        title: 'Create your first Space (with the help of AI)',
        titleSubtext:
          'Give us a few details about your use case or use one of our examples. AI will create the first Valuecase Space for you.',
        leftButton: (
          <Button2 variant='plain' leadingIcon='arrow-back-outline' onClick={handleBack}>
            Back
          </Button2>
        ),
        rightButton: (
          <Button2
            trailingIcon='arrow-forward-outline'
            onClick={handleCreateTemplateAndSpace}
            disabled={!templateGenerationInputsSatisfied}
          >
            Next
          </Button2>
        ),
      },
      {
        id: 'space',
        icon: 'briefcase',
        component: SpaceCreationStep,
        title: 'Final Step: Which customer is your first Space for?',
        titleSubtext: 'We’ll use this to automatically personalise your Space.',
        rightButton: (
          <Button2
            trailingIcon='arrow-forward-outline'
            onClick={handleCreateSpace}
            disabled={!spaceCreationInputsSatisfied}
          >
            Create Space
          </Button2>
        ),
      },
      {
        id: 'loading',
        component: CreateTemplateAndSpaceLoadingStep,
        takesUpFullScreen: true,
      },
    ],
    [
      handleOnboardTenant,
      tenantSettingsInputsSatisfied,
      handlePromptSelect,
      handleUseCaseSelect,
      handlePromptChange,
      handleBack,
      handleCreateTemplateAndSpace,
      templateGenerationInputsSatisfied,
      handleCreateSpace,
      spaceCreationInputsSatisfied,
    ],
  )

  if (isMobile) {
    return (
      <div className='flex items-center justify-center h-screen w-screen bg-gradient-to-b from-background to-blue-s2'>
        <div className='text-center flex flex-col gap-2'>
          <span className='text-2xl font-bold'>
            Valuecase is best experienced <br /> on desktop.
          </span>
          <span className='text-lg'>We recommend you switch here.</span>
          <Button2
            className='mt-2'
            onClick={() =>
              (window.location.href = `mailto:?body=${encodeURIComponent('https://app.valuecase.com/dashboard')}&subject=Valuecase`)
            }
          >
            Send this link to yourself
          </Button2>
        </div>
      </div>
    )
  }

  return (
    <>
      <FullScreenFlow
        isOpen={isOpen}
        currentStep={currentStep}
        steps={steps}
        componentProps={{
          form,
          onUpdateForm: handleUpdateForm,
        }}
        className='bg-gradient-to-b from-background to-blue-s2'
      />

      {/* Intercom component - Re-added because Onboarding flow is outside of the context of routes and so on where it usually lives */}
      <IntercomComponent hideDefaultLauncher={false} />
    </>
  )
}
