type LogoResult = {
  file: File
  url: string
} | null
export async function fetchLogoFromUrl(url: string): Promise<LogoResult> {
  let cleanUrl = url
  if (cleanUrl.includes('http://') || cleanUrl.includes('https://')) {
    cleanUrl = cleanUrl.replaceAll('http://', '').replaceAll('https://', '')
  }

  try {
    const response = await fetch(`https://cdn.brandfetch.io/${cleanUrl}`)

    if (!response.ok) {
      return null
    }

    const file = new File([await response.blob()], `${crypto.randomUUID()}.png`, {
      type: 'image/png',
    })

    return {
      file,
      url: cleanUrl,
    }
  } catch (err) {
    return null
  }
}
