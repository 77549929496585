import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button2,
  OneLineTruncatedText,
} from '@valuecase/ui-components'
import { FC, useMemo } from 'react'
import {
  CellDetailsConfig,
  TSpaceSummaryQueryFilter,
  useActionPlanAnalyticsCellDetailsQuery,
} from './action-plan-analytics.helpers'

export const ActionPlanAnalyticsCellDialog: FC<{
  open?: boolean
  onOpenChange?: (open: boolean) => void
  cellDetailsConfig: CellDetailsConfig | undefined
  filters: TSpaceSummaryQueryFilter
}> = ({ open, onOpenChange, cellDetailsConfig, filters }) => {
  const queryFilters = useMemo(
    () => ({
      ...filters,
      cellDetailsConfig,
    }),
    [filters, cellDetailsConfig],
  )

  const cellDetailsQuery = useActionPlanAnalyticsCellDetailsQuery({
    filters: queryFilters,
  })

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{cellDetailsConfig?.title}</DialogTitle>
          <DialogDescription>{cellDetailsConfig?.description}</DialogDescription>
        </DialogHeader>
        <div className='max-h-[290px] overflow-y-auto'>
          <Table className='w-full grid grid-cols-[1fr_max-content_max-content]'>
            <TableHeader className='grid grid-cols-subgrid col-span-full w-full'>
              {(cellDetailsConfig?.groupType === 'quickest' ||
                cellDetailsConfig?.groupType === 'slowest' ||
                cellDetailsConfig?.groupType === 'days to completion') && (
                <TableRow className='grid grid-cols-subgrid col-span-full w-full'>
                  <TableHead className='py-1'>Space</TableHead>
                  <TableHead className='py-1'>Days to completion</TableHead>
                  <TableHead className='py-1'></TableHead>
                </TableRow>
              )}
              {(cellDetailsConfig?.groupType === 'completed' ||
                cellDetailsConfig?.groupType === 'space count') && (
                <TableRow className='grid grid-cols-subgrid col-span-full w-full'>
                  <TableHead className='py-1'>Space</TableHead>
                  <TableHead className='py-1'>Is Completed</TableHead>
                  <TableHead className='py-1'></TableHead>
                </TableRow>
              )}
            </TableHeader>
            {(cellDetailsConfig?.groupType === 'quickest' ||
              cellDetailsConfig?.groupType === 'slowest' ||
              cellDetailsConfig?.groupType === 'days to completion') && (
              <TableBody className='grid grid-cols-subgrid col-span-full w-full'>
                {cellDetailsQuery.data?.map((space) => (
                  <TableRow
                    key={space.spaceRootNodeId}
                    className='grid grid-cols-subgrid col-span-full w-full items-center'
                  >
                    <TableCell className='py-1'>
                      <OneLineTruncatedText>{space.spaceName}</OneLineTruncatedText>
                    </TableCell>
                    <TableCell className='py-1'>{space.daysToComplete}</TableCell>
                    <TableCell className='py-1'>
                      <Button2
                        onClick={() => {
                          const url = new URL(location.origin)
                          url.pathname = `/spaces/${space.spaceRootNodeId}`
                          url.searchParams.set('authToken', space.spaceAuthToken)
                          if (space.actionPlanNodeId) {
                            url.searchParams.set('block', space.actionPlanNodeId)
                          }
                          window.open(url.toString(), '_blank')
                        }}
                        variant={'ghost'}
                        trailingIcon='open-outline'
                        className='h-7'
                      >
                        {queryFilters.groupingLevel === 'template'
                          ? 'Open Space'
                          : 'Open Action Plan'}
                      </Button2>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
            {(cellDetailsConfig?.groupType === 'completed' ||
              cellDetailsConfig?.groupType === 'space count') && (
              <TableBody className='grid grid-cols-subgrid col-span-full w-full'>
                {cellDetailsQuery.data
                  ?.filter(
                    (row) =>
                      // If space found, include all rows
                      cellDetailsConfig?.groupType !== 'completed' ||
                      // If completed space found, include only completed rows
                      (cellDetailsConfig?.groupType === 'completed' && row.daysToComplete !== null),
                  )
                  .map(
                    (space: {
                      spaceRootNodeId: string
                      spaceName: string
                      daysToComplete: number | null
                      spaceAuthToken: string
                      actionPlanNodeId?: string
                    }) => (
                      <TableRow
                        key={space.spaceRootNodeId}
                        className='grid grid-cols-subgrid col-span-full w-full items-center'
                      >
                        <TableCell className='py-1'>
                          <OneLineTruncatedText>{space.spaceName}</OneLineTruncatedText>
                        </TableCell>
                        <TableCell className='py-1'>
                          {typeof space.daysToComplete === 'number' ? '✅' : 'No'}
                        </TableCell>
                        <TableCell className='py-1'>
                          <Button2
                            onClick={() => {
                              const url = new URL(location.origin)
                              url.pathname = `/spaces/${space.spaceRootNodeId}`
                              url.searchParams.set('authToken', space.spaceAuthToken)
                              if (space.actionPlanNodeId) {
                                url.searchParams.set('block', space.actionPlanNodeId)
                              }
                              window.open(url.toString(), '_blank')
                            }}
                            variant={'ghost'}
                            trailingIcon='open-outline'
                            className='h-7'
                          >
                            {queryFilters.groupingLevel === 'template'
                              ? 'Open Space'
                              : 'Open Action Plan'}
                          </Button2>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
              </TableBody>
            )}
          </Table>
        </div>
      </DialogContent>
    </Dialog>
  )
}
