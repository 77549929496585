import { ScrollArea } from '@valuecase/ui-components'
import { motion } from 'framer-motion'
import { useMemo } from 'react'
import { useTemplateGeneration } from '../context/TemplateGenerationContext'

export const TemplateGenerationReviewStage = () => {
  const { isStaging, currentRequest } = useTemplateGeneration()
  const pages = useMemo(() => currentRequest?.previewResponse?.preview ?? [], [currentRequest])

  return (
    <div className='flex flex-col h-full'>
      <span className='text-xs font-semibold text-grey-s6 mt-8'>Pages</span>
      <ScrollArea className='flex-1  mt-3' scrollThumbClassName='bg-white/85'>
        <div className='pr-4 max-h-[400px]'>
          {isStaging
            ? [1, 2, 3, 4].map((i) => (
                <motion.div
                  key={i}
                  className='flex flex-col gap-2 bg-[#00000003] p-2 rounded-lg animate-pulse mb-3'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: i * 0.1 }}
                >
                  <div className='h-4 w-24 bg-white/45 rounded' />
                  <div className='h-3 w-48 bg-white/45 rounded' />
                </motion.div>
              ))
            : pages.map((page: { title: string; description: string }) => (
                <PagePreviewBlock key={page.title} {...page} />
              ))}
        </div>
      </ScrollArea>

      <div className='mt-8 text-center'>
        <span className='text-sm text-grey-s6'>
          Is it looking good? <br /> Create a Template or revisit the instructions.
        </span>
      </div>
    </div>
  )
}

const PagePreviewBlock = ({ title, description }: { title: string; description: string }) => {
  return (
    <div className='flex flex-col gap-1 bg-white/50 p-2 rounded-lg mb-3'>
      <span className='text-sm font-semibold'>{title}</span>
      <span className='text-xs text-grey-s5'>{description}</span>
    </div>
  )
}
