type PromptCategory = {
  promptName: string
  description: string
  prompt: string
}

type UseCasePrompts = {
  [key: string]: PromptCategory[]
}

export const promptsByUseCase: UseCasePrompts = {
  sales: [
    {
      promptName: 'Outbound email',
      description: 'Simple email sequence template',
      prompt:
        "Create a simple template we can use in our outbound email sequences. They should include an intro to our product, which of their problems we solve, and who we work with. Let's also include a scheduler as well as more contact information. One page might be enough, but just to test, also add a page with more in-depth resources and links.",
    },
    {
      promptName: 'Enterprise sales',
      description: 'Complex enterprise sales process',
      prompt:
        "Create a template we can use in our complex enterprise sales process that runs 4-6 months. It must include a block on page 1 in which we can explain our understanding of their status quo and goals, a business case, a mutual action plan from discovery to go-live including stakeholder management, as well as deep dive resources organized by stakeholder group. Add anything else you'd deem necessary for an enterprise sales process.",
    },
  ],
  onboarding: [
    {
      promptName: 'Low-touch',
      description: 'Fast self-service onboarding',
      prompt:
        'Create a template for a fast low-touch onboarding process of 15-30 days. The portal must include an onboarding plan on one page that takes the customer by hand through preparing the account, logging in, doing the setup steps, and launching the solution. In the preparation, customers need to fill out a form among other things. In the setup, a key step is to set up various integrations which are different customer by customer. We want to share as many resources as possible so customers can self-serve. We want to reduce our effort to a minimum.',
    },
    {
      promptName: 'High-touch',
      description: 'Complex guided onboarding',
      prompt:
        "Create a template for a complex onboarding process of around 60 days. We start with a kickoff and then align the plan there. Depending on which products the customers choose, they get user trainings and setup plans. We want to shorten our onboarding process, track everything, and give the customer transparency during the onboarding. Also, we want to increase the self-sufficiency of our customers by enabling them to find all required resources in the portal. However, we'll still have meetings with them and do some handholding. They'll be personally in touch with our team.",
    },
  ],
  other: [
    {
      promptName: 'Partner Portal',
      description: 'Strategic collaboration portal',
      prompt:
        'Create a partner portal to strengthen collaborations with our strategic partners, offering resources like integration guides and marketing toolkits. We also want an onboarding plan for them. The portal should facilitate clear communication, support co-marketing efforts, and provide a feedback mechanism. We aim to enhance partner satisfaction and streamline joint projects.',
    },
    {
      promptName: 'Quarterly business review',
      description: 'Customer success quarterly business review',
      prompt:
        'Create a two-page Quarterly Business Review (QBR) template to present our customer success metrics and highlight value delivered to clients. The template should cover sections such as key achievements, usage analytics, impact on client KPIs, and proposed action items for the next quarter. Our aim is to strengthen client relationships, demonstrate ROI, and collaboratively plan for continued success.',
    },
  ],
}
